angular.module("PartsRatingService", [])

    .service("PartsRatingService", ["$http", "$location", "$route", "$localStorage", "$rootScope", "GlobalNotificationService", "API", function ($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API) {

        /*'GET' request to get orders*/
        this.getPartsRating = function (data) {
            return $http({
                method: 'GET',
                url: API.url + '/rating',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    date_start: data.date_start,
                    date_end: data.date_end,
                    page: data.page,
                    per_page: data.per_page,
                    order_field: data.order_field,
                    order: data.order
                }
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                if (response.status === 401) {
                    $location.path('/login');
                }
            });
        };


    }] );