angular.module('CampaignsAddCtrl', [])
    .controller("CampaignsAddCtrl", ["$scope", "$http", "Auth", "$window", "$rootScope", "$location", "Upload", "$localStorage", "APP_INFO", "$timeout", "$q", "GlobalNotificationService", "CampaignsService", "SharedService", function($scope, $http, Auth, $window, $rootScope, $location, Upload, $localStorage, APP_INFO, $timeout, $q, GlobalNotificationService, CampaignsService, SharedService) {

        $scope.goBack = function () {
            $location.path('/campaigns');
        };

        $scope.selectedCampaignType = {
            selected: ''
        };
        $scope.activate_campaign = false;
        $scope.campaigns_period_from = moment($rootScope.currentDate).format('YYYY-MM-DD');
        $scope.dateOptions = {
            changeYear: true,
            changeMonth: true,
            dateFormat: "dd-mm-yy"
        };

        /* bootstrap bug fix when modal doesn't close when you go backwards while modal is open */
        $scope.$on("$destroy", function(){
            angular.element(document.querySelector(".modal-backdrop")).remove();
            angular.element(document.querySelector("body")).removeClass("modal-open");
        });

        angular.element("#sendPushNotification").on('hidden.bs.modal', function(){
            $scope.goBack();
            $scope.$apply();
        });

        $scope.tagHandler = function (tag){
            return { name: tag }
        };

        $scope.campaignTypeList = [{
            name: 'Акция',
            type: 1
        }];
        $scope.segmentsList = [
            {
                name: 'СТО',
                type: 1
            }, {
                name: 'Магазин',
                type: 2
            }, {
                name: 'Автопарк',
                type: 3
            }, {
                name: 'Владелец автомобиля',
                type: 4
            }
        ];

        $scope.selectedArticles = {selected: []};
        $scope.selectedBrands = {selected: []};
        $scope.selectedSegments = {selected: []};
        $scope.selectedRegions = {selected: []};
        $scope.selectedCampaignType = {
            selected: ''
        };
        $scope.campaigns_discount = 0;
        $scope.ru_regions_checkbox = false;

        $scope.test = function (url, pic) {
            console.log(url);
            console.log(pic);
        };

        $scope.dateValidation = function () {
            ($scope.campaigns_period_from >= $scope.campaigns_period_to) ? $scope.dateError = true : $scope.dateError = false;
        };
        $scope.addAllRussianRegions = function () {
            if ($scope.ru_regions_checkbox) {
                $scope.ru_regions_checkbox = false;
            } else {
                $scope.ru_regions_checkbox = true;
                $scope.selectedRegions = {selected: []};
            }
        };
        $scope.uploadImg = function (dataUrl, name) {
            $scope.imgData = {
                croppedPhotoUrl: dataUrl,
                photo_name: name
            };
            angular.element('#addImageModal').modal('hide');
        };
        $scope.delPhoto = function () {
            $scope.imgData = {};
        };
        var blobImageUpload = function (camp_id) {
            return new Promise(function (resolve, reject) {
                if ($scope.imgData) {
                    CampaignsService.addCampaignPhoto({
                        discount_company_id: camp_id,
                        campaign_photo: $scope.imgData.croppedPhotoUrl,
                        campaign_photo_name: $scope.imgData.photo_name,
                        campaign_creation: true
                    }).then(function () {
                        resolve();
                    })
                } else {
                    resolve();
                }
            })
        };

        $scope.getBrands = function (str) {
            if (str.length > 2) {
                SharedService.getBrands({
                    type: 1,
                    brand_name: str
                }).then(function (response) {
                    $scope.brandList = [];
                    for ( var i = 0; i < response.result.length; i++) {
                        $scope.brandList.push({name: response.result[i]});
                    }
                })
            }
        };

        var getRegions = function () {
            CampaignsService.getRegions().then(function (response) {
                console.log(response);
                $scope.regionsList = response.result;
            })
        };
        getRegions();

        $scope.saveCampaign = function () {
            $scope.loadingIndicator = true;
            var requests = [];
            var deferred = $q.defer();
            requests.push(deferred.promise);

            var parsedDateFrom = moment($scope.campaigns_period_from).format("YYYY-MM-DD");
            var parsedDateTo = moment($scope.campaigns_period_to).format("YYYY-MM-DD");
            var spare_parts_article = [];
            var segments = [];
            var brands = [];
            var regions = [];

            for (var a = 0; a < $scope.selectedArticles.selected.length; a++) {
                spare_parts_article.push({article: $scope.selectedArticles.selected[a].name});
            };

            for (var s = 0; s < $scope.selectedSegments.selected.length; s++) {
                segments.push($scope.selectedSegments.selected[s].type);
            };

            for (var b = 0; b < $scope.selectedBrands.selected.length; b++) {
                brands.push($scope.selectedBrands.selected[b].name);
            };

            for (var r = 0; r < $scope.selectedRegions.selected.length; r++) {
                regions.push({place_id: $scope.selectedRegions.selected[r].place_id});
            };


            console.log({
                discount_company_id: null,
                name: $scope.campaigns_name,
                description: $scope.campaigns_description,
                type: $scope.selectedCampaignType.selected.type,
                date_start: parsedDateFrom,
                date_end: parsedDateTo,
                discount: $scope.campaigns_discount,
                regions: !$scope.ru_regions_checkbox ? regions : [],
                spare_parts: spare_parts_article,
                brands: brands,
                segments: segments,
                is_active: $scope.activate_campaign ? 1 : 0
            });
            CampaignsService.createCampaigns({
                discount_company_id: null,
                name: $scope.campaigns_name,
                description: $scope.campaigns_description,
                type: $scope.selectedCampaignType.selected.type,
                date_start: parsedDateFrom,
                date_end: parsedDateTo,
                discount: $scope.campaigns_discount,
                regions: !$scope.ru_regions_checkbox ? regions : [],
                spare_parts: spare_parts_article,
                brands: brands,
                segments: segments,
                is_active: $scope.activate_campaign ? 1 : 0
            }).then(function (response) {
                console.log(response);

                $scope.campaignId = response.result.id;
                blobImageUpload(response.result.id)
                    .then(function () {
                        deferred.resolve('SYNCHRONOUS');
                    });
                //$scope.goBack();
            });

            $q.all(requests)
                .then(function () {
                    if ($scope.activate_campaign) {
                        angular.element('#sendPushNotification').modal('show');
                    } else {
                        $location.path('/campaigns');
                    }
                    GlobalNotificationService.trigger('Кампания успешно создана', 'success');
                    $scope.loadingIndicator = false;
                })
        };


        $scope.sendPushNotification = function (message) {
            $scope.loadingIndicator = true;
            console.log({
                message: message,
                discount_company_id: $scope.campaignId,
                type: 2
            });
            SharedService.sendPushNotification({
                message: message,
                discount_company_id: $scope.campaignId,
                type: 2
            }).then(function (res) {
                angular.element('#sendPushNotification').modal('hide');
                $timeout(function () {
                    $scope.loadingIndicator = false;
                });
                $location.path('/campaigns');
            })
        };



    }]);