/* HEADER CONTROLLER */
angular.module('HeaderCtrl', [])
    .controller("HeaderCtrl", ["$scope", "Auth", "$location", "APP_INFO", "$localStorage", function($scope, Auth, $location, APP_INFO, $localStorage) {

        $scope.APP_INFO = APP_INFO;

        $scope.logout = function () {
            Auth.logout();
            $location.path("/login");
            $location.search('active', null)
        };




    }]);