angular.module('CampaignsServices', [])

    .service("CampaignsService", ["$http", '$location', "$route", "$localStorage", "$rootScope", 'GlobalNotificationService', 'API', 'APP_INFO', 'Upload', function($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API, APP_INFO, Upload) {

        /* 'POST' REQUEST TO CREATE CAMPAIGNS */
        this.createCampaigns = function (data) {
            return $http({
                method: 'POST',
                url: API.url + 'discount',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                data: {
                    userId: $localStorage.user.id,
                    discount_company_id: data.discount_company_id,
                    name: data.name,
                    description: data.description ? data.description : '',
                    type: data.type,
                    date_start: data.date_start,
                    date_end: data.date_end,
                    discount: data.discount,
                    regions: data.regions ? data.regions : '',
                    spare_parts: data.spare_parts ? data.spare_parts : '',
                    brands: data.brands ? data.brands : '',
                    segments: data.segments ? data.segments : '',
                    is_active: data.is_active,
                    push_message: data.push_message ? data.push_message : null
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log('crash');
                GlobalNotificationService.trigger(response.status, 'error');
            });
        };

        /* 'GET' REQUEST TO GET LIST OF CAMPAIGNS */
        this.getCampaigns = function (data) {
            return $http({
                method: 'GET',
                url: API.url + 'discount',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    discount_company_id: data.discount_company_id ? data.discount_company_id : null,
                    regions: data.regions ? data.regions.toString() : '',
                    segments: data.segments ? data.segments.toString() : ''
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };

        /* 'POST' REQUEST TO CHANGE CAMPAIGN STATUS */
        this.updateCampaignsStatus = function (data) {
            return $http({
                method: 'POST',
                url: API.url + 'discount/status',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                data: {
                    userId: $localStorage.user.id,
                    discount_company_id: data.discount_company_id,
                    discount_company_status: data.discount_company_status
                }
            }).then(function successCallback(response) {
                console.log(response);
                GlobalNotificationService.trigger('Статус кампании успешно изменен', 'success');
                return response.data;
            }, function errorCallback(response) {
                GlobalNotificationService.trigger(response.status, 'error');
            });
        };

        /* 'GET' REQUEST TO GET LIST OF CAMPAIGNS */
        this.getRegions = function (data) {
        	console.log(APP_INFO['env']);
            return $http({
                method: 'GET',
                url: API.url + 'location/region',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    country_id: APP_INFO['env'] === 'prod' ? 1 : 2
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };

        /* 'POST' REQUEST TO ADD CAMPAIGN PHOTO */
        this.addCampaignPhoto = function (data) {
            return Upload.upload({
                method: 'POST',
                /*url: 'https://angular-file-upload-cors-srv.appspot.com/upload',*/
                url: API.url + 'discount/image',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                data: {
                    userId: $localStorage.user.id,
                    discount_company_id: data.discount_company_id,
                    discount_company_image: Upload.dataUrltoBlob(data.campaign_photo, data.campaign_photo_name)
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response;
            }, function errorCallback(response) {
                console.log(response);
                GlobalNotificationService.trigger(response.statusText, 'error');
            })
        };

        /* 'POST' REQUEST TO DELETE CAMPAIGN PHOTO */
        this.deleteCampaignPhoto = function (data) {
            return Upload.upload({
                method: 'POST',
                /*url: 'https://angular-file-upload-cors-srv.appspot.com/upload',*/
                url: API.url + 'discount/image',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                data: {
                    userId: $localStorage.user.id,
                    discount_company_id: data.discount_company_id
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response;
            }, function errorCallback(response) {
                console.log(response);
                GlobalNotificationService.trigger(response.statusText, 'error');
            })
        };

        /* 'DELETE' REQUEST TO DELETE CAMPAIGNS */
        this.deleteCampaigns = function (data) {
            return $http({
                method: 'DELETE',
                url: API.url + 'discount',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    company_ids: data.company_ids
                }
            }).then(function successCallback(response) {
                console.log(response);
                GlobalNotificationService.trigger('Кампания успешно удалена', 'success');
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };





    }]);

