angular.module('appScroll', [])
    .directive("scroll", ['$window', function ($window) {
        return function(scope, element, attrs) {
            var actionFixedBar = angular.element('.action-fixed-bar');
            angular.element($window).bind("scroll", function() {
                if (this.pageYOffset >= 1) {
                    actionFixedBar.addClass('fixed_for_sure');
                } else {
                    actionFixedBar.removeClass('fixed_for_sure');
                }
                scope.$apply();
            });
        };
    }]);