angular.module("RegisteredUsersService", [])

    .service("RegisteredUsersService", ["$http", "$location", "$route", "$localStorage", "$rootScope", "GlobalNotificationService", "API", function ($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API) {

        /*'GET' request to get orders*/
        this.getRegisteredUsers = function (data) {
            return $http({
                method: 'GET',
                url: API.url + 'operator/users',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    date_start: data.date_start,
                    region_id: data.region_id ? data.region_id.toString() : '',
                    date_end: data.date_end,
                    page: data.page,
                    per_page: data.per_page,
                    order_field: data.order_by,
                    order: data.sort,
                    search_string: data.search_string
                }
            }).then(function successCallback(response) {
                console.log('test-duplicate-requests');
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                if (response.status === 401) {
                    $location.path('/login');
                }
            });
        };

        /* EXPORT TO XLS */
        this.exportXLS = function () {
            return $http({
                method: 'GET',
                url: API.url + 'operator/users/export',
                headers: {
                    Authorization: $localStorage.user.api_key,
                    'Content-type': 'application/json'
                },
                responseType: 'blob',
                params: {
                    userId: $localStorage.user.id
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response;
            }, function errorCallback(response) {
                console.log(response);
                GlobalNotificationService.trigger(response.statusText, 'error');
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };

        /* EDIT USER SEGMENT */
        this.editUserSegment = function (data) {
            return $http({
                method: 'POST',
                url: API.url + 'operator/user',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    type: data.type,
                    user_id: data.user_id
                }
            }).then(function successCallback(response) {
                GlobalNotificationService.trigger('Вы успешно изменили сегмент', 'success');
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                if (response.status === 401) {
                    $location.path('/login');
                }
            });
        }

    }] );