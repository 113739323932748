//SingleOrderCtrl
angular.module('SingleOrderCtrl', [])
    .controller("SingleOrderCtrl", ["$scope", "$http", "Auth", "$rootScope","$location", "$routeParams", "$localStorage", "PickUpPlacesServices", "APP_INFO", "$timeout", "OrdersService", "SharedService", function($scope, $http, Auth, $rootScope, $location, $routeParams, $localStorage, PickUpPlacesServices, APP_INFO, $timeout, OrdersService, SharedService) {

        $scope.addNewProduct = false;
        $scope.loadingIndicator = false;
        $scope.reverseOrder = true;
        $scope.sortField = 'part_name';

        $scope.statuses = [
            /*{
                status: 0,
                name: 'Новый заказ'
            },*/ {
                status: 1,
                name: 'Взять в обработку'
            }, {
                status: 2,
                name: 'Принять заказ'
            }, {
                status: 3,
                name: 'Отказ'
            }, {
                status: 4,
                name: 'Готов к отгрузке'
            }, {
                status: 5,
                name: 'Отгружен'
            }/*, {
                status: 6,
                name: 'Отменен'
            }*/

        ];

        $scope.push = {
            message: ''
        };

        $scope.pick_up_places = {
            selected: ''
        };

        $('#editOrder').on('hidden.bs.modal', function (e) {
            $scope.dataObject.order_phone = $scope.singleOrderData.order_phone;
            $scope.dataObject.order_username = $scope.singleOrderData.order_username;
            if ($scope.singleOrderData.status === 1) {
                $scope.statuses.selected = {
                    status: 1,
                    name: 'Взять в обработку'
                }
            }else if ($scope.singleOrderData.status === 2) {
                $scope.statuses.selected = {
                    status: 2,
                    name: 'Принять заказ'
                }
            } else if ($scope.singleOrderData.status === 3) {
                $scope.statuses.selected = {
                    status: 3,
                    name: 'Отказ'
                }
            } else if ($scope.singleOrderData.status === 4) {
                $scope.statuses.selected = {
                    status: 4,
                    name: 'Готов к отгрузке'
                }
            } else if ($scope.singleOrderData.status === 5) {
                $scope.statuses.selected = {
                    status: 5,
                    name: 'Отгружен'
                }
            } else if ($scope.singleOrderData.status === 6) {
                $scope.statuses.selected = {
                    status: 6,
                    name: 'Отменен'
                }
            } else {
                $scope.statuses.selected = {
                    status: 0,
                    name: 'Новый заказ'
                }
            }
            for (var p = 0; p < $scope.pickUpPlacesList.length; p++) {
                if ($scope.pickUpPlacesList[p].id === $scope.dataObject.warehouse.id) {
                    console.log($scope.pickUpPlacesList[p]);
                    $scope.pick_up_places = {
                        selected: {
                            id: $scope.pickUpPlacesList[p].id,
                            description: $scope.pickUpPlacesList[p].description
                        }
                    };
                }
            };
            console.log($scope.editOrderForm);
        });

        //$scope.newData = {};
        $scope.sortBy = function(sortField) {
            $scope.reverseOrder = ($scope.sortField === sortField) ? !$scope.reverseOrder : false;
            $scope.sortField = sortField;
        };

        $scope.goBack = function () {
            $location.path('/orders');
        };

        var getPickUpPlaces = function () {
            PickUpPlacesServices.getPickUpPlaces().then(function (response) {
                console.log(response);
                $scope.pickUpPlacesList = response.result;
                for (var p = 0; p < $scope.pickUpPlacesList.length; p++) {
                    if ($scope.pickUpPlacesList[p].id === $scope.dataObject.warehouse.id) {
                        console.log($scope.pickUpPlacesList[p]);
                        $scope.pick_up_places = {
                            selected: {
                                id: $scope.pickUpPlacesList[p].id,
                                description: $scope.pickUpPlacesList[p].description
                            }
                        };
                    }
                }
            })
        };

        var getSingleOrder = function () {
            OrdersService.getSingleOrder($routeParams.order_id).then(function (response) {
                $scope.singleOrderData = response.result[0];
                $scope.singleOrderItemsData = response.result[0].order_items;

                if ($scope.singleOrderData.status === 1) {
                    $scope.statuses.selected = {
                        status: 1,
                        name: 'Взять в обработку'
                    }
                }else if ($scope.singleOrderData.status === 2) {
                    $scope.statuses.selected = {
                        status: 2,
                        name: 'Принять заказ'
                    }
                } else if ($scope.singleOrderData.status === 3) {
                    $scope.statuses.selected = {
                        status: 3,
                        name: 'Отказ'
                    }
                } else if ($scope.singleOrderData.status === 4) {
                    $scope.statuses.selected = {
                        status: 4,
                        name: 'Готов к отгрузке'
                    }
                } else if ($scope.singleOrderData.status === 5) {
                    $scope.statuses.selected = {
                        status: 5,
                        name: 'Отгружен'
                    }
                } else if ($scope.singleOrderData.status === 6) {
                    $scope.statuses.selected = {
                        status: 6,
                        name: 'Отменен'
                    }
                } else {
                    $scope.statuses.selected = {
                        status: 0,
                        name: 'Новый заказ'
                    }
                }

                $scope.dataObject = Object.assign({}, $scope.singleOrderData);
                console.log($scope.dataObject);

                getPickUpPlaces();
            })
        };

        getSingleOrder();


        $scope.editOrdersModalShow = function () {
            angular.element('#editOrder').modal('show');
        };
        $scope.orderObject = {};
        //$scope.newData;
        //for (var i = 0; i <= $scope.singleOrderItemsData.length; i++) {
            //console.log($scope.singleOrderItemsData[i].id);
            //$scope.newData[i]['order_item_id'] = $scope.singleOrderItemsData[i].id;
            //$scope.newData[i]['quantity'] = $scope.singleOrderItemsData[i].quantity;
        //}

        $scope.editOrderFormSubmit = function (address, username, userphone, status_selected) {
            var objToSend = [];
            if ($scope.singleOrderItemsData) {
                for (var i = 0; i < $scope.singleOrderItemsData.length; i++) {
                    objToSend.push({
                        'order_item_id': $scope.singleOrderItemsData[i].id,
                        'article': $scope.singleOrderItemsData[i].article,
                        'id_provider': $scope.singleOrderItemsData[i].id_provider,
                        'part_name': $scope.singleOrderItemsData[i].part_name,
                        'price': $scope.singleOrderItemsData[i].price,
                        'quantity': $scope.singleOrderItemsData[i].quantity
                    });
                }
            }

            $scope.loadingIndicator = true;
            OrdersService.editSingleOrder({
                order_id: $routeParams.order_id,
                address: $scope.dataObject.delivery_id !== 1 ? address : null,
                username: username,
                phone_number: userphone,
                order_items: objToSend ? objToSend : '',
                status: status_selected,
                location_id_from: $scope.dataObject.delivery_id === 1 ? $scope.pick_up_places.selected.location_id : null
            }).then(function (response) {
                angular.element('#editOrder').modal('hide');
                $timeout(function () {
                    $scope.loadingIndicator = false;
                }, 500);
                getSingleOrder();
                angular.element('#sendPushNotification').modal('show');
            })
        };
        
        $scope.addProduct = function ( article, part_name, price, discount_price, speed_stat, quantity) {
            //$scope.loadingIndicator = true;

            OrdersService.addProductItem({
                order_id: $routeParams.order_id,
                article: article,
                part_name: part_name,
                price: price,
                discount_price: discount_price,
                speed_stat: speed_stat,
                quantity: quantity
            }).then(function (response) {
                getSingleOrder();
                $scope.newItemArticle = '';
                $scope.newItemPartName = '';
                $scope.newItemPrice = '';
                $scope.newItemDiscountPrice = '';
                $scope.newItemSpeedStat = '';
                $scope.newItemQuantity = '';
                console.log(response);
            })

        }

        $scope.showDeleteConfirm = function (id) {
            $scope.elementForDelete = id;
            angular.element('#deleteItems').modal('show');
        };

        $scope.deleteItems = function (id) {
            //$scope.loadingIndicator = true;
            OrdersService.deleteItems({
                order_id: $routeParams.order_id,
                order_items_id: id
            }).then(function (response) {
                getSingleOrder();
                //angular.element('#deleteItems').modal('hide');
                /*$timeout(function () {
                    $scope.loadingIndicator = false;
                }, 500);*/
            })
        };

        $scope.sendPushNotification = function (message, form) {
            $scope.loadingIndicator = true;
            console.log({
                message: message,
                user_ids: $scope.singleOrderData.user_id,
                type: 3
            });
            SharedService.sendPushNotification({
                message: message,
                user_ids: $scope.singleOrderData.user_id,
                type: 3
            }).then(function (res) {
                angular.element('#sendPushNotification').modal('hide');
                $scope.push = {
                    message: ''
                };
                resetForm(form);
                $timeout(function () {
                    $scope.loadingIndicator = false;
                });
            })
        };

        var resetForm = function (form) {
            form.$setUntouched();
            form.$setPristine();
        };
        
    }]);