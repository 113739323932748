angular.module('SettingsCtrl', [])
    .controller("SettingsCtrl", ["$scope", "$http", "Auth", "$rootScope","$location", "$localStorage", "APP_INFO", "$timeout", "SettingsServices", "FilterService", function($scope, $http, Auth, $rootScope, $location, $localStorage, APP_INFO, $timeout, SettingsServices, FilterService) {

        $scope.goBack = function () {
            $location.path('/orders');
        };

        var getSupportSettings = function () {
            SettingsServices.getSupportSettings().then(function (response) {
                console.log(response);
                if (response.result.length) {
                    $scope.supportId = response.result[0].id;
                    $scope.support_email = response.result[0].email;
                    $scope.support_phone = response.result[0].phone_number;
                }

            })
        };
        getSupportSettings();



        $scope.saveSettings = function () {
            console.log({
                support_id: $scope.supportId ? $scope.supportId : null,
                phone_number: $scope.support_phone,
                email: $scope.support_email
            });
            SettingsServices.updateSupportSettings({
                support_id: $scope.supportId ? $scope.supportId : null,
                phone_number: $scope.support_phone,
                email: $scope.support_email
            }).then(function (response) {
                console.log(response);
                $scope.goBack();
            })
        };

    }]);