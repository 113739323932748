angular.module('AuthServices', ['ngResource', 'ngStorage'])
    .factory('Auth', ['$resource', '$rootScope', '$localStorage', '$q', 'GlobalNotificationService', 'API', function($resource, $rootScope, $localStorage, $q, GlobalNotificationService, API){

        /**
         *  User profiles resource
         */
        var Profile = $resource(API.url + 'operator/login', {}, {
            login: {
                method: "POST",
                isArray : false
            }
        });


        var auth = {};

        /**
         *  Saves the current user in the root scope
         *  Call this in the app run() method
         */
        auth.init = function(){
            if (auth.isLoggedIn()){
                $rootScope.user = auth.currentUser();
            }
        };

        auth.login = function(data){
            return $q(function(resolve, reject){
                Profile.login({email:data.email, password:data.password}).$promise
                    .then(function(data) {
                        console.log(data);
                        $localStorage.user = data.result;
                        $localStorage.user.user_permissions = [];
                        //$localStorage.user.user_permissions.push(data.user.role);
                        $rootScope.user = $localStorage.user;
                        resolve();
                    }, function(response) {
                        console.log(response);
                        if (response.status === 404) {
                            GlobalNotificationService.trigger('Неправильный логин или пароль', 'error');
                        } else {
                            GlobalNotificationService.trigger(response.status, 'error');
                        }
                        /*reject();*/
                    });
            });
        };


        auth.logout = function() {
            $localStorage.$reset();
            delete $rootScope.user;
        };


        auth.checkPermissionForView = function(view) {
            if (!view.requiresAuthentication) {
                return true;
            }

            return userHasPermissionForView(view);
        };


        var userHasPermissionForView = function(view){
            if(!auth.isLoggedIn()){
                return false;
            }

            if(!view.permissions || !view.permissions.length){
                return true;
            }

            return auth.userHasPermission(view.permissions);
        };


        auth.userHasPermission = function(permissions){
            if(!auth.isLoggedIn()){
                return false;
            }

            var found = false;
            angular.forEach(permissions, function(permission, index){
                if ($localStorage.user.user_permissions.indexOf(permission) >= 0){
                    found = true;
                    //return;
                }
            });

            return found;
        };


        auth.currentUser = function(){
            return $localStorage.user;
        };


        auth.isLoggedIn = function(){
            return $localStorage.user != null;
        };


        return auth;
    }]);