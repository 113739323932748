var app = angular.module('app', [
    'app.routes',
    'app.config',
    'ngRoute',
    'ngFileUpload',
    'ngImgCrop',
    'angular-loading-bar',
    'angularjs-dropdown-multiselect',
    'ngResource',
    'ngStorage',
    'angularUtils.directives.dirPagination',
    'appPaginationState',
    'appScroll',
    'selectionModel',
    'ui.date',
    'ui.select',
    'ngSanitize',
    'ngMap',
    'daterangepicker',
    'AuthServices',
    'NotificationsServices',
    'appNotificationHandler',
    '404Ctrl',
    'HeaderCtrl',
    'FooterCtrl',
    'DashboardCtrl',
    'LoginCtrl',
    'OrdersCtrl',
    'ClosedOrdersCtrl',
    'OrdersService',
    'SingleOrderCtrl',
    'appDatepickerOptionsOrder',
    'RegisteredUsersService',
    'RegisteredUsersCtrl',
    'PricingService',
    'PricingCtrl',
    'FilterService',
    'PartsRatingCtrl',
    'PartsRatingService',
    'appNoCommaFilter',
    'CampaignsCtrl',
    'CampaignsServices',
    'PickUpPlacesCtrl',
    'PickUpPlacesServices',
    'SettingsServices',
    'SettingsCtrl',
    'SharedService'
]);

app.config(['$locationProvider', '$rootScopeProvider', function($locationProvider, $rootScopeProvider) {

    $rootScopeProvider.digestTtl(10);
    /*** DELETING # FROM THE URL */
    $locationProvider.hashPrefix('');
    $locationProvider.html5Mode(false);


    /*** MULTILANGUAGE PROVIDER SETTINGS */
   /*$translateProvider.useStaticFilesLoader({
        prefix: './assets/i18n/locale-',
        suffix: '.json'
    });*/
    //$translateProvider.preferredLanguage('ru');
    //$translateProvider.determinePreferredLanguage();
    //$translateProvider.useSanitizeValueStrategy('escape');
    //$translateProvider.useCookieStorage();
    //$translateProvider.useLocalStorage();
    //$translateProvider.useMissingTranslationHandlerLog();
    //$translateProvider.useLoaderCache(true);

    /*** IDLING SERVICE CONFIGURATIONS */
        //IdleProvider.idle(3600); // 1 hour (3600)
        //IdleProvider.timeout(120); // 60s timer
    //KeepaliveProvider.interval(10);

    // Interceptor to handle cached templates problem on each new build
    /*$httpProvider.interceptors.push(function() {
        return {
            'request': function(config) {
                if (!config.cached && config.url.indexOf('.html') > -1) {
                    if (config.url.indexOf("?") > -1) {
                        config.url = config.url.replace("?","?v=" + version);
                    }
                    else{
                        config.url += "?v=" + version;
                    }
                }
                return config;
            }
        };
    });*/

}]);

app.run(['$rootScope', '$location', 'Auth', '$localStorage', '$templateCache', function ($rootScope, $location, Auth, $localStorage,$templateCache) {

    /*** INIT OF THE AUTH SERVICE */
    Auth.init();
    //IdlingService.idleTracker();
    $rootScope.$on('$routeChangeSuccess', function (event, absNewUrl, current) {

        if (absNewUrl.originalPath === '/login') {
            $('.menu-item').hide();
        } else {
            $('.menu-item').show();
        }
        /*** GETTING PAGINATION ID FROM ROUTE PARAMS */
        $rootScope.pagination_id = absNewUrl.pagination_id;
    });

    $rootScope.currentDate = new Date();

    /*** BEFORE ROUTE CHANGE SUCCESS IT CHECKS IF USER HAS PERMISSIONS TO VISIT A REQUESTED PAGE */
    $rootScope.$on('$routeChangeStart', function (event, next, current) {
        if (!Auth.checkPermissionForView(next)){
            $localStorage.pathBefore = $location.path();
            event.preventDefault();
            $location.path("/login");
        } else {
            if ($location.path() === '/') {
                $location.path("/orders");
            }
        }
    });

    // FIX OF A FIREFOX BEING UNNABLE TO HANDLE .click() EVENT (.img, .xls downloads)
    /*HTMLElement.prototype.click = function() {
        var evt = this.ownerDocument.createEvent('MouseEvents');
        evt.initEvent('click', true, true, this.ownerDocument.defaultView, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
        this.dispatchEvent(evt);
    };*/

}]);