angular.module('OrdersCtrl', [])
    .controller("OrdersCtrl", ["$scope", "$http", "Auth", "$rootScope","$location", "$localStorage", "APP_INFO", "$timeout", "OrdersService", "SharedService", function($scope, $http, Auth, $rootScope, $location, $localStorage, APP_INFO, $timeout, OrdersService, SharedService) {

        $scope.loadingIndicator = false;
        $scope.ordersList = [];
        $scope.onPagePaginations = 2;

        $scope.loadingFilterIndicator = false;
        $scope.filter_was_changed = false;

        $scope.activeSearch = false;
        $scope.search = '';

        var userPushIds = [];

        $scope.statuses = [
            /*{
                status: 0,
                name: 'Новый заказ'
            },*/ {
                status: 1,
                name: 'Взять в обработку'
            }, {
                status: 2,
                name: 'Подтвердить заказ'
            }, {
                status: 3,
                name: 'Отказ'
            }, {
                status: 4,
                name: 'Готов к отгрузке'
            }, {
                status: 5,
                name: 'Отгружен'
            }/*, {
                status: 6,
                name: 'Отменен'
            }*/

        ];
        $scope.push = {
            message: ''
        };

        $scope.filterDataOrders = {
            filterPaymentStatusData: [
                {
                    id: 1,
                    label: 'Неоплачен'
                }, {
                    id: 2,
                    label: 'В обработке'
                }, {
                    id: 3,
                    label: 'Оплачен'
                }, {
                    id: 4,
                    label: 'Ошибка платежа'
                }
            ],
            filterStatusData: [
                {
                    id: 0,
                    label: 'Новый заказ'
                }, {
                    id: 1,
                    label: 'В обработке'
                }, {
                    id: 2,
                    label: 'Подтверждён'
                },/* {
                    id: 3,
                    label: 'Отказ'
                }, */{
                    id: 4,
                    label: 'Готов к отгрузке'
                }/*, {
                    id: 5,
                    label: 'Отгружен'
                }, {
                    id: 6,
                    label: 'Отменен'
                }*/
            ]
        };
        $scope.filterSelectedOrders = {
            filterPaymentStatusSelected: [],
            filterStatusSelected: []
        };

        $scope.filter_date_range = {
            date: {
                startDate: $localStorage.advanced_filters_data_orders ? $localStorage.advanced_filters_data_orders.filter.dateSelected.startDate : moment(),
                endDate: $localStorage.advanced_filters_data_orders ? $localStorage.advanced_filters_data_orders.filter.dateSelected.endDate : moment()
            },
            options: {
                alwaysShowCalendars: true,
                showDropdowns: true,
                timePicker: true,
                timePicker24Hour: true,
                timePickerIncrement: 10,
                showCustomRangeLabel: false,
                parentEl: '.server-side-filters',
                buttonClasses: "btn btn-sm datepicker-btns",
                applyButtonClasses: "btn-primary datepicker-apply",
                cancelClass: "btn-default datepicker-cancel",
                opens: "right",
                locale: {
                    format: "DD-MM-YYYY",
                    separator: " - ",
                    applyLabel: "Применить фильтр",
                    cancelLabel: "Отмена",
                    fromLabel: "From",
                    toLabel: "To",
                    customRangeLabel: "Custom",
                    weekLabel: "W",
                    daysOfWeek: [
                        "вс",
                        "пн",
                        "вт",
                        "ср",
                        "чт",
                        "пт",
                        "сб"
                    ],
                    monthNames: [
                        "Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"
                    ],
                    firstDay: 1
                },
                ranges: {
                    'Сегодня': [moment(), moment()],
                    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
                    'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
                    'С начала месяца': [moment().startOf('month'), moment().endOf('month')],
                    'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'За все время': [moment("2018-08-01" ,"YYYY-MM-DD"), moment()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev, picker) {
                        /*$localStorage.advanced_filters_data_orders.filter['dateSelected'] = {
                            startDate: $scope.filter_date_range.date.startDate.format('YYYY-MM-DD'),
                            endDate: $scope.filter_date_range.date.endDate.format('YYYY-MM-DD')
                        };
                        $scope.currentPage1 = 1;
                        $scope.getOrders($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);*/
                    }
                }
            }
        };
        $scope.filters = {
            filterPaymentStatusTranslate: {
                checkAll: 'Все',
                uncheckAll: 'Удалить все',
                searchPlaceholder: 'Поиск...',
                buttonDefaultText: 'Статус оплаты'
            },
            filterPaymentStatusSettings: {
                //selectionLimit: 1,
                enableSearch: true,
                smartButtonMaxItems: 2,
                scrollableHeight: 'auto',
                scrollable: true,
                keyboardControls: true,
                styleActive: true,
                show: true,
                idProperty: 'id',
                smartButtonTextConverter: function(itemText, originalItem) {
                    return itemText;
                }
            },
            filterPaymentStatusEvents: {
                onItemSelect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onItemDeselect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onSelectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                },
                onDeselectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                }
            },

            filterStatusTranslate: {
                checkAll: 'Все',
                uncheckAll: 'Удалить все',
                searchPlaceholder: 'Поиск...',
                buttonDefaultText: 'Статус заказа'
            },
            filterStatusSettings: {
                //selectionLimit: 1,
                enableSearch: true,
                smartButtonMaxItems: 2,
                scrollableHeight: 'auto',
                scrollable: true,
                keyboardControls: true,
                styleActive: true,
                show: true,
                idProperty: 'id',
                smartButtonTextConverter: function(itemText, originalItem) {
                    return itemText;
                }
            },
            filterStatusEvents: {
                onItemSelect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onItemDeselect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onSelectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                },
                onDeselectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                }
            }
        };

        /*** START:: PAGINATION STATE CONTROLLING FUNCTIONS */
        /* TIMEOUT IS USED TO ENABLE THE FUNCTIONS AND ASSIGNMENTS INITIATE AFTER DOM IS RENDERED AND TO PREVENT ERRORS OF TYPE 'undefined' */
        $timeout(function () {

            if (!$localStorage.advanced_filters_data_orders) {
                $localStorage.advanced_filters_data_orders = {};
                $localStorage.advanced_filters_data_orders['filter'] = {
                    dateSelected: {
                        startDate: $scope.filter_date_range.date.startDate.format('YYYY-MM-DD'),
                        endDate: $scope.filter_date_range.date.endDate.format('YYYY-MM-DD')
                    }
                };
            };

            /*if (!$localStorage.filterSelectedOrders) {
                $localStorage.filterSelectedOrders = $scope.filterSelectedOrders;
            } else {
                $scope.filterSelectedOrders = $localStorage.filterSelectedOrders;
            }*/

            if ($localStorage.filterSelectedOrders) {
                $scope.filterSelectedOrders = JSON.parse(JSON.stringify($localStorage.filterSelectedOrders));
            };


            var q = $localStorage.pagination_state[$rootScope.pagination_id];
            var reverseOrder1 = (q.pagination_1.reverseOrder === null) ? true : q.pagination_1.reverseOrder;
            $scope.pageSize1 = q.pagination_1.pageSize;
            $scope.currentPage1 = q.pagination_1.currentPage;
            $scope.sortField = q.pagination_1.sortField || 'created_at';
            $scope.reverseOrder = reverseOrder1;

            $scope.getOrders($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);

        }, 0);

        /* ON PAGE CHANGE HANDLER SETS A NEW PAGE AND A PAGE SIZE PARAMETERS TO SPECIFIC OBJECT IN localstorage.pagination_state  */
        $scope.pageSizeHandler = function (size, pagination_id) {
            $scope.pageSize1 = size;
            $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['pageSize'] = size;
            $scope.getOrders($scope.currentPage1, size, $scope.sortField, $scope.reverseOrder, $scope.search);
        };
        $scope.pageChangeHandler = function (page, old_page, size, pagination_id) {
            if (page !== old_page) {
                $scope.currentPage1 = page;
                $scope.pageSize1 = size;
                $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['currentPage'] = page;
                $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['pageSize'] = size;
                $scope.getOrders(page, size, $scope.sortField, $scope.reverseOrder, $scope.search);
            }
        };

        $scope.keySearch = function () {
            $scope.loadingFilterIndicator = true;
            $scope.activeSearch = true;
            $scope.getOrders($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
        };
        $scope.resetSearch = function () {
            $scope.loadingFilterIndicator = true;
            $scope.search = '';
            $scope.getOrders($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
            $scope.activeSearch = false;
        };

        /* WHEN SORT FUNCTION TRIGGERED, HANDLER SETS A NEW SOFT FIELD DATA AND SORT ORDER PARAMETERS TO SPECIFIC OBJECT IN localstorage.pagination_state  */
        $scope.sortBy = function(sortField) {
            $scope.reverseOrder = ($scope.sortField === sortField) ? !$scope.reverseOrder : false;
            $scope.sortField = sortField;
            $scope.getOrders($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['sortField'] = sortField;
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['reverseOrder'] = $scope.reverseOrder;
        };

        /*** END:: PAGINATION STATE CONTROLLING FUNCTIONS */

        var postStructure = function () {
            var filter = $scope['filterSelectedOrders'];
            var filter_date = $scope['filter_date_range'];

            var toSend = {
                payment_status_ids: [],
                status_ids: [],
                date_from: (moment(filter_date.date.startDate, 'YYYY-MM-DD HH:mm a ', true).isValid()) ? filter_date.date.startDate.format('YYYY-MM-DD') : filter_date.date.startDate,
                date_to: (moment(filter_date.date.endDate, 'YYYY-MM-DD HH:mm a ', true).isValid()) ? filter_date.date.endDate.format('YYYY-MM-DD') : filter_date.date.endDate
            };

            for (var w = 0; w < filter.filterPaymentStatusSelected.length; w++) {
                toSend.payment_status_ids.push(filter.filterPaymentStatusSelected[w].id);
            }

            for (var s = 0; s < filter.filterStatusSelected.length; s++) {
                toSend.status_ids.push(filter.filterStatusSelected[s].id);
            }

            return toSend;

        };

        $scope.getOrders = function (page, per_page, order_by, sort, search_string) {

            var toSend = postStructure();
            console.log(toSend.status_ids);
            OrdersService.getOrders({
                date_start: toSend.date_from,
                date_end: toSend.date_to,
                payment_status: toSend.payment_status_ids,
                status: toSend.status_ids.length ? toSend.status_ids : [0,1,2,4],
                page: page,
                per_page: per_page,
                order_by: order_by,
                sort: sort ? 'desc' : 'asc',
                search_string: search_string
            }).then(function (response) {
            /*OrdersService.getOrders(, toSend.date_to, page, per_page, order_by, (sort) ? 'desc' : 'asc').then(function (response) {*/
                $scope.ordersList = response.result;
                $scope.ordersCount = response.quantity;

                $scope.ordersList.forEach(function (t) {
                    t['statuses'] = $scope.statuses;
                });

                /*if ($localStorage.filterSelectedOrders) {
                    $scope.filterSelectedOrders = JSON.parse(JSON.stringify($localStorage.filterSelectedOrders));
                };*/

                $timeout(function () {
                    $scope.loadingFilterIndicator = false;
                    $scope.filter_was_changed = false;
                }, 0);

                for (var key in $scope.filterSelectedOrders) {
                    if ($scope.filterSelectedOrders[key].length) {
                        $scope.filterReadyForClear = true;
                        return false;
                    } else {
                        $scope.filterReadyForClear = false;
                    }
                };
            })
        };

        $scope.showProductsTitles = function (e) {
            angular.element(e.target.previousElementSibling).show();
            angular.element(e.target.parentElement.previousElementSibling).show();
            angular.element(e.target).hide();
        };
        $scope.hideProductsTitles = function (e) {
            angular.element(e.target.parentElement.previousElementSibling).hide();
            angular.element(e.target.nextElementSibling).show();
            angular.element(e.target).hide();
        };


        $scope.goSingleOrder = function (id) {
            console.log(id);
            $location.path('/orders/' + id);
        };

        $scope.clearSelected = function () {
            $scope.statuses.selected = undefined;
        };

        $scope.setStatusOrder = function (item) {
            userPushIds = [];
            userPushIds.push(item.user_id);
            console.log({
                status: item.selected.status,
                order_id: item.id
            });
            OrdersService.editSingleOrderStatus({
                status: item.selected.status,
                order_id: item.id
            }).then(function (response) {
                $scope.clearSelected();
                $scope.getOrders($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
                angular.element('#sendPushNotification').modal('show');
            })
        };

        $scope.sendPushNotification = function (message, form) {
            $scope.loadingIndicator = true;
            console.log({
                message: message,
                user_ids: userPushIds,
                type: 3
            });
            SharedService.sendPushNotification({
                message: message,
                user_ids: userPushIds,
                type: 3
            }).then(function (res) {
                angular.element('#sendPushNotification').modal('hide');
                userPushIds = [];
                $scope.push = {
                    message: ''
                };
                resetForm(form);
                $timeout(function () {
                    $scope.loadingIndicator = false;
                });
            })
        };

        var resetForm = function (form) {
            form.$setUntouched();
            form.$setPristine();
        };

        $scope.resetFilter = function () {
            $scope.loadingFilterIndicator = true;
            $scope.filterReadyForClear = false;
            $scope.filter_date_range.date = {
                startDate: moment(),
                endDate: moment()
            };
            $localStorage.advanced_filters_data_orders['filter'].dateSelected = {
                startDate: moment($scope.filter_date_range.date.startDate).format('YYYY-MM-DD'),
                endDate: moment($scope.filter_date_range.date.endDate).format('YYYY-MM-DD')
            };
            $scope.filterSelectedOrders = {
                filterPaymentStatusSelected: [],
                filterStatusSelected: []
            };
            $localStorage.filterSelectedOrders = $scope.filterSelectedOrders;
            $scope.currentPage1 = 1;
            $scope.getOrders($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
        };

        $scope.acceptFilter = function () {
            $scope.loadingFilterIndicator = true;
            $localStorage.advanced_filters_data_orders['filter'].dateSelected = {
                startDate: moment($scope.filter_date_range.date.startDate).format('YYYY-MM-DD'),
                endDate: moment($scope.filter_date_range.date.endDate).format('YYYY-MM-DD')
            };
            $localStorage.filterSelectedOrders = $scope.filterSelectedOrders;
            $scope.currentPage1 = 1;
            $scope.getOrders($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
            console.log($scope.filterReadyForClear);
        };

        $scope.$watchGroup(['filter_date_range.date'], function(newV, oldV){
            if (newV !== oldV) {
                $scope.filter_was_changed = true;
            }
        }, true);


    }]);