angular.module('app.routes', ['ngRoute'])
    .config(['$routeProvider', function($routeProvider) {

        /***
         ROUTES TO ALL EXISTING PAGES AND SUBPAGES WITH AUTH CHECK AND PERMISSION BASED.
         'pagination_id' parameter is used to assign specific id to a page and after, control the state of the paginations on these pages.
         */

        $routeProvider
            .when('/', {
                templateUrl: 'app/components/dashboard/dashboard.tpl.html',
                controller: 'DashboardCtrl',
                requiresAuthentication: true,
                pagination_id: 1
            })
            .when('/login', {
                templateUrl: 'app/components/login/login.tpl.html',
                controller: 'LoginCtrl',
                pagination_id: 2
            })
            .when('/orders', {
                templateUrl: 'app/components/orders/orders.tpl.html',
                controller: 'OrdersCtrl',
                requiresAuthentication: true,
                pagination_id: 3
            })
            .when('/closed-orders', {
                templateUrl: 'app/components/orders/closed-orders/closed-orders.tpl.html',
                controller: 'ClosedOrdersCtrl',
                requiresAuthentication: true,
                pagination_id: 4
            })
            .when('/orders/:order_id', {
                templateUrl: 'app/components/orders/single-order/single-order.tpl.html',
                controller: 'SingleOrderCtrl',
                requiresAuthentication: true,
                pagination_id: 5
            })
            .when('/users', {
                templateUrl: 'app/components/registered-users/registered-users.tpl.html',
                controller: 'RegisteredUsersCtrl',
                requiresAuthentication: true,
                pagination_id: 6
            })
            .when('/pricing', {
                templateUrl: 'app/components/pricing/pricing.tpl.html',
                controller: 'PricingCtrl',
                requiresAuthentication: true,
                pagination_id: 7
            })
            .when('/campaigns', {
                templateUrl: 'app/components/campaigns/campaigns.tpl.html',
                controller: 'CampaignsCtrl',
                requiresAuthentication: true,
                pagination_id: 8
            })
            .when('/campaigns/add', {
                templateUrl: 'app/components/campaigns/campaigns-add/campaigns-add.tpl.html',
                controller: 'CampaignsAddCtrl',
                requiresAuthentication: true,
                pagination_id: 9
            })
            .when('/campaigns/edit/:campaign_id', {
                templateUrl: 'app/components/campaigns/campaigns-edit/campaigns-edit.tpl.html',
                controller: 'CampaignsEditCtrl',
                requiresAuthentication: true,
                pagination_id: 10
            })
            .when('/parts-rating', {
                templateUrl: 'app/components/parts-rating/parts-rating.tpl.html',
                controller: 'PartsRatingCtrl',
                requiresAuthentication: true,
                pagination_id: 11
            })
            .when('/pick-up-places', {
                templateUrl: 'app/components/pick-up-places/pick-up-places.tpl.html',
                controller: 'PickUpPlacesCtrl',
                requiresAuthentication: true,
                pagination_id: 12
            })
            .when('/pick-up-places/add', {
                templateUrl: 'app/components/pick-up-places/pick-up-places-add/places-add.tpl.html',
                controller: 'PlacesAddCtrl',
                requiresAuthentication: true,
                pagination_id: 13
            })
            .when('/pick-up-places/edit/:place_id', {
                templateUrl: 'app/components/pick-up-places/pick-up-places-edit/places-edit.tpl.html',
                controller: 'PlacesEditCtrl',
                requiresAuthentication: true,
                pagination_id: 14
            })
            .when('/settings', {
                templateUrl: 'app/components/settings/settings.tpl.html',
                controller: 'SettingsCtrl',
                requiresAuthentication: true,
                pagination_id: 15
            })


            .otherwise({
                templateUrl: 'app/components/404/404.tpl.html',
                controller: '404Ctrl',
                pagination_id: 99
            });

    }]);