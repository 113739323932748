angular.module('RegisteredUsersCtrl', [])
    .controller("RegisteredUsersCtrl", ["$scope", "$http", "Auth", "$rootScope","$location", "$localStorage", "APP_INFO", "$timeout", "RegisteredUsersService", "FilterService", "SharedService", function($scope, $http, Auth, $rootScope, $location, $localStorage, APP_INFO, $timeout, RegisteredUsersService, FilterService, SharedService) {

        $scope.usersList = [];
        $scope.onPagePaginations = 1;

        $scope.loadingFilterIndicator = false;
        $scope.filter_was_changed = false;

        $scope.activeSearch = false;
        $scope.search = '';

        $scope.userSegmentSelect = {
            selected: ''
        };
        $scope.selectedList = [];
        $scope.showColumn = false;
        $scope.checkAll = {
            data: false
        };

        $scope.filterDataUser = {
            filterRegionsData: []
        };
        $scope.filterSelectedUser = {
            filterRegionsSelected: []
        };

        $scope.filter_date_range = {
            date: {
                startDate: $localStorage.advanced_filters_data_users ? $localStorage.advanced_filters_data_users.filter.dateSelected.startDate : moment(),
                endDate: $localStorage.advanced_filters_data_users ? $localStorage.advanced_filters_data_users.filter.dateSelected.endDate : moment()
            },
            options: {
                alwaysShowCalendars: true,
                showDropdowns: true,
                timePicker: true,
                timePicker24Hour: true,
                timePickerIncrement: 10,
                showCustomRangeLabel: false,
                parentEl: '.server-side-filters',
                buttonClasses: "btn btn-sm datepicker-btns",
                applyButtonClasses: "btn-primary datepicker-apply",
                cancelClass: "btn-default datepicker-cancel",
                opens: "right",
                locale: {
                    format: "DD-MM-YYYY",
                    separator: " - ",
                    applyLabel: "Применить фильтр",
                    cancelLabel: "Отмена",
                    fromLabel: "From",
                    toLabel: "To",
                    customRangeLabel: "Custom",
                    weekLabel: "W",
                    daysOfWeek: [
                        "вс",
                        "пн",
                        "вт",
                        "ср",
                        "чт",
                        "пт",
                        "сб"
                    ],
                    monthNames: [
                        "Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"
                    ],
                    firstDay: 1
                },
                ranges: {
                    'Сегодня': [moment(), moment()],
                    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
                    'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
                    'С начала месяца': [moment().startOf('month'), moment().endOf('month')],
                    'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'За все время': [moment("2018-08-01" ,"YYYY-MM-DD"), moment()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev, picker) {
                        /*$localStorage.advanced_filters_data_users.filter['dateSelected'] = {
                            startDate: $scope.filter_date_range.date.startDate.format('YYYY-MM-DD'),
                            endDate: $scope.filter_date_range.date.endDate.format('YYYY-MM-DD')
                        };
                        $scope.currentPage1 = 1;
                        $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);*/
                    }
                }
            }
        };
        $scope.filters = {
            filterRegionsTranslate: {
                checkAll: 'Все',
                uncheckAll: 'Удалить все',
                searchPlaceholder: 'Поиск...',
                buttonDefaultText: 'Регионы'
            },
            filterRegionsSettings: {
                enableSearch: true,
                smartButtonMaxItems: 2,
                scrollableHeight: 'auto',
                scrollable: true,
                keyboardControls: true,
                styleActive: true,
                show: true,
                idProperty: 'id',
                smartButtonTextConverter: function(itemText, originalItem) {
                    return itemText;
                }
            },
            filterRegionsEvents: {
                onItemSelect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onItemDeselect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onSelectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                },
                onDeselectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                }
            }
        };

        $scope.userSegmentList = [
            {
                name: 'СТО',
                type: 1
            }, {
                name: 'Магазин',
                type: 2
            }, {
                name: 'Автопарк',
                type: 3
            }, {
                name: 'Владелец автомобиля',
                type: 4
            }
        ];

        $scope.showColumns = function () {
            $scope.showColumn = !$scope.showColumn;
        };

        var resetForm = function (form) {
            form.$setPristine();
            form.$setUntouched()
        };

        /*** START:: PAGINATION STATE CONTROLLING FUNCTIONS */
        /* TIMEOUT IS USED TO ENABLE THE FUNCTIONS AND ASSIGNMENTS INITIATE AFTER DOM IS RENDERED AND TO PREVENT ERRORS OF TYPE 'undefined' */
        $timeout(function () {

            if (!$localStorage.advanced_filters_data_users) {
                $localStorage.advanced_filters_data_users = {};
                $localStorage.advanced_filters_data_users['filter'] = {
                    dateSelected: {
                        startDate: $scope.filter_date_range.date.startDate.format('YYYY-MM-DD'),
                        endDate: $scope.filter_date_range.date.endDate.format('YYYY-MM-DD')
                    }
                };
            }

            /*if (!$localStorage.filterSelectedUser) {
                $localStorage.filterSelectedUser = $scope.filterSelectedUser;
            } else {
                $scope.filterSelectedUser = $localStorage.filterSelectedUser;
            }*/

            if ($localStorage.filterSelectedUser) {
                $scope.filterSelectedUser = JSON.parse(JSON.stringify($localStorage.filterSelectedUser));
            };

            var q = $localStorage.pagination_state[$rootScope.pagination_id];
            var reverseOrder1 = (q.pagination_1.reverseOrder === null) ? false : q.pagination_1.reverseOrder;
            $scope.pageSize1 = q.pagination_1.pageSize;
            $scope.currentPage1 = q.pagination_1.currentPage;
            $scope.sortField = q.pagination_1.sortField || 'username';
            $scope.reverseOrder = reverseOrder1;

            $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);

        }, 0);

        /* ON PAGE CHANGE HANDLER SETS A NEW PAGE AND A PAGE SIZE PARAMETERS TO SPECIFIC OBJECT IN localstorage.pagination_state  */
        $scope.pageSizeHandler = function (size, pagination_id) {
            $scope.pageSize1 = size;
            $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['pageSize'] = size;
            $scope.getRegisteredUsers($scope.currentPage1, size, $scope.sortField, $scope.reverseOrder, $scope.search);
        };
        $scope.pageChangeHandler = function (page, old_page, size, pagination_id) {
            if (page !== old_page) {
                $scope.currentPage1 = page;
                $scope.pageSize1 = size;
                $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['currentPage'] = page;
                $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['pageSize'] = size;
                $scope.getRegisteredUsers(page, size, $scope.sortField, $scope.reverseOrder, $scope.search);
            }
        };

        $scope.keySearch = function () {
            $scope.loadingFilterIndicator = true;
            $scope.activeSearch = true;
            $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
        };
        $scope.resetSearch = function () {
            $scope.loadingFilterIndicator = true;
            $scope.search = '';
            $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
            $scope.activeSearch = false;
        };

        /* WHEN SORT FUNCTION TRIGGERED, HANDLER SETS A NEW SOFT FIELD DATA AND SORT ORDER PARAMETERS TO SPECIFIC OBJECT IN localstorage.pagination_state  */
        $scope.sortBy = function(sortField) {
            $scope.reverseOrder = ($scope.sortField === sortField) ? !$scope.reverseOrder : false;
            $scope.sortField = sortField;
            $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['sortField'] = sortField;
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['reverseOrder'] = $scope.reverseOrder;
        };
        /*** END:: PAGINATION STATE CONTROLLING FUNCTIONS */

        var getRegions = function () {
            FilterService.getRegions().then(function (response) {
                for (var i = 0; i < response.data.result.length; i++) {
                    $scope.filterDataUser.filterRegionsData.push({
                        id: response.data.result[i].id,
                        label: response.data.result[i].description
                    });
                }
                console.log($scope.filterDataUser.filterRegionsData);
            })
        };
        getRegions();

        var postStructure = function () {
            var filter = $scope['filterSelectedUser'];
            var filter_date = $scope['filter_date_range'];
            var toSend = {
                region_ids: [],
                date_from: (moment(filter_date.date.startDate, 'YYYY-MM-DD HH:mm a ', true).isValid()) ? filter_date.date.startDate.format('YYYY-MM-DD') : filter_date.date.startDate,
                date_to: (moment(filter_date.date.endDate, 'YYYY-MM-DD HH:mm a ', true).isValid()) ? filter_date.date.endDate.format('YYYY-MM-DD') : filter_date.date.endDate
            };

            for (var w = 0; w < filter.filterRegionsSelected.length; w++) {
                toSend.region_ids.push(filter.filterRegionsSelected[w].id);
            }

            return toSend;
        };
        $scope.getRegisteredUsers = function (page, per_page, order_by, sort, search_string) {

            var toSent = postStructure();

            RegisteredUsersService.getRegisteredUsers({
                date_start: toSent.date_from,
                date_end: toSent.date_to,
                region_id: toSent.region_ids,
                page: page,
                per_page: per_page,
                order_by: order_by,
                sort: sort ? 'desc' : 'asc',
                search_string: search_string
            }).then(function (response) {
                console.log(response);
                for (var i = 0; i < response.result.length; i++) {
                    if (response.result[i].created_at) {
                        response.result[i].created_at.date = moment(response.result[i].created_at.date).format("YYYY-MM-DD HH:mm:ss");
                    }
                }
                $scope.usersList = response.result;
                $scope.usersCount = response.count;

                $timeout(function () {
                    $scope.loadingFilterIndicator = false;
                    $scope.filter_was_changed = false;
                }, 0);

                for (var key in $scope.filterSelectedUser) {
                    if ($scope.filterSelectedUser[key].length) {
                        $scope.filterReadyForClear = true;
                        return false;
                    } else {
                        $scope.filterReadyForClear = false;
                    }
                };
            })
        };

        $scope.exportXLS = function (e) {
            RegisteredUsersService.exportXLS()
                .then(function(response) {
                    console.log(response);
                    var blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    var urlCreator = window.URL || window.webkitURL;
                    var anchor = angular.element('<a/>');
                    anchor.attr({
                        href: urlCreator.createObjectURL(blob),
                        target: '_blank',
                        download: 'AUT_Registered_Users.xlsx'
                    })[0].click();
                });
        };

        $scope.editUserSegmentModal = function (item) {
            angular.element('#editUser').modal('show');
            $scope.userIdSelected = item.id;
            for (var i = 0; i < $scope.userSegmentList.length; i++) {
                if (item.type === $scope.userSegmentList[i].type) {
                    $scope.userSegmentSelect.selected = $scope.userSegmentList[i];
                }
            }

        };

        $scope.editUserSegment = function (form) {
            console.log({
                type: $scope.userSegmentSelect.selected ? $scope.userSegmentSelect.selected.type : '',
                user_id: $scope.userIdSelected
            });
            RegisteredUsersService.editUserSegment({
                type: $scope.userSegmentSelect.selected ? $scope.userSegmentSelect.selected.type : '',
                user_id: $scope.userIdSelected
            }).then(function (response) {
                console.log(response);
                angular.element('#editUser').modal('hide');
                resetForm(form);
                $scope.userIdSelected = '';
                $scope.userSegmentSelect = {
                    selected: ''
                };
                $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);

            });
        };

        $scope.showPushModal = function (id) {
            id === 1 ? $scope.user_ids = [] : $scope.user_ids = $scope.selectedList;
            console.log($scope.selectedList);
            angular.element('#sendPushNotification').modal('show');
        };

        $scope.sendPushNotification = function (message, form) {
            $scope.loadingIndicator = true;
            console.log({
                message: message,
                user_ids: $scope.user_ids
            });
            SharedService.sendPushNotification({
                message: message,
                user_ids: $scope.user_ids,
                type: 1
            }).then(function (res) {
                $scope.user_ids = [];
                angular.element('#sendPushNotification').modal('hide');
                $scope.showColumn = false;
                $scope.clearCheckbox();
                $timeout(function () {
                    $scope.loadingIndicator = false;
                })
            })
        };

        /* CHECKING THE ALL CHECKBOXES IN PRODUCT ITEMS LIST */
        $scope.checkAllItems = function() {
            console.log($scope.selectedItems);
            if(!$scope.checkAll.data) {
                $scope.checkAll.data = true;
                angular.forEach($scope.usersList, function(item) {
                    item.selected = true;
                    //console.log(item);
                });
            } else {
                $scope.checkAll.data = false;
                angular.forEach($scope.usersList, function(item) {
                    item.selected = false;
                });
            }
        };
        $scope.uncheckAllItems = function() {
            angular.forEach($scope.usersList, function(item) {
                item.selected = false;
                $scope.checkAll['data'] = false;
            });
        };
        $scope.clearCheckbox = function () {
            angular.forEach($scope.usersList, function(item) {
                $scope.checkAll['data'] = false;
                item.selected = false;
            });
        };

        /* COUNTER FOR SELECTED ROWS */
        $scope.$watch('usersList', function(items){

            var selectedItems = 0;

            angular.forEach(items, function(item){
                selectedItems += item.selected ? 1 : 0;
                if (selectedItems > 0) {
                    (selectedItems === $scope.usersList.length) ? $scope.checkAll['data'] = true : $scope.checkAll['data'] = false;
                }

                if (selectedItems > 0 && item.selected === true) {
                    if (!$scope.selectedList.includes(item.id)) {
                        $scope.selectedList.push(item.id);
                    }

                } else if (selectedItems === 0) {
                    $scope.selectedList = [];
                }

            });
            //console.log(selectedItems);
            $scope.selectedItems = selectedItems;
        }, true);

        $scope.resetFilter = function () {
            $scope.loadingFilterIndicator = true;
            $scope.filterReadyForClear = false;
            $scope.filter_date_range.date = {
                startDate: moment(),
                endDate: moment()
            };
            $localStorage.advanced_filters_data_users['filter'].dateSelected = {
                startDate: moment($scope.filter_date_range.date.startDate).format('YYYY-MM-DD'),
                endDate: moment($scope.filter_date_range.date.endDate).format('YYYY-MM-DD')
            };
            $scope.filterSelectedUser = {
                filterRegionsSelected: []
            };
            $localStorage.filterSelectedUser = $scope.filterSelectedUser;
            $scope.currentPage1 = 1;
            $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
        };

        $scope.acceptFilter = function () {
            $scope.loadingFilterIndicator = true;
            $localStorage.advanced_filters_data_users['filter'].dateSelected = {
                startDate: moment($scope.filter_date_range.date.startDate).format('YYYY-MM-DD'),
                endDate: moment($scope.filter_date_range.date.endDate).format('YYYY-MM-DD')
            };
            $localStorage.filterSelectedUser = $scope.filterSelectedUser;
            $scope.currentPage1 = 1;
            $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder, $scope.search);
            console.log($scope.filterReadyForClear);
        };

        $scope.$watchGroup(['filter_date_range.date'], function(newV, oldV){
            if (newV !== oldV) {
                $scope.filter_was_changed = true;
            }
        }, true);

    }]);