angular.module('PickUpPlacesServices', [])

    .service("PickUpPlacesServices", ["$http", '$location', "$route", "$localStorage", "$rootScope", 'GlobalNotificationService', 'API', 'Upload', function($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API, Upload) {

        /* 'GET' REQUEST TO GET LIST OF PICK UP PLACES */
        this.getPickUpPlaces = function (data) {
            return $http({
                method: 'GET',
                url: API.url + 'warehouse',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };

        /* 'GET' REQUEST TO GET LIST OF PICK UP PLACES */
        this.getSinglePickUpPlace = function (data) {
            return $http({
                method: 'GET',
                url: API.url + 'warehouse',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    warehouse_id: data.warehouse_id
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };

        /* 'POST' REQUEST TO SET PICK UP PLACES */
        this.createEditPickUpPlaces = function (data) {
            return $http({
                method: 'POST',
                url: API.url + 'warehouse',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    warehouse_id: data.warehouse_id ? data.warehouse_id : null,
                    description: data.description,
                    phone_number: data.phone_number,
                    mode_data: data.mode_data,
                    place_id: data.place_id
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };

        /* 'DELETE' REQUEST TO DELETE PICK UP PLACES */
        this.deletePickUpPlace = function (data) {
            return $http({
                method: 'DELETE',
                url: API.url + 'warehouse',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    warehouse_ids: data.warehouse_ids
                }
            }).then(function successCallback(response) {
                console.log(response);
                GlobalNotificationService.trigger('Пункт выдачи успешно удален', 'success');
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };


    }]);

