angular.module('PickUpPlacesCtrl', [
    'PlacesAddCtrl',
    'PlacesEditCtrl'
])
    .controller("PickUpPlacesCtrl", ["$scope", "$http", "Auth", "$rootScope","$location", "$localStorage", "APP_INFO", "$timeout", "PickUpPlacesServices", "FilterService", function($scope, $http, Auth, $rootScope, $location, $localStorage, APP_INFO, $timeout, PickUpPlacesServices, FilterService) {

        $scope.onPagePaginations = 1;

        $scope.goToEdit = function (place) {
            $location.path('/pick-up-places/edit/' + place.id);
        };

        $timeout(function () {

            /*if (!$localStorage.filterSelectedCampaigns) {
                $localStorage.filterSelectedCampaigns = $scope.filterSelectedCampaigns;
            } else {
                $scope.filterSelectedCampaigns = $localStorage.filterSelectedCampaigns;
            }*/

            var q = $localStorage.pagination_state[$rootScope.pagination_id];
            var reverseOrder1 = (q.pagination_1.reverseOrder === null) ? true : q.pagination_1.reverseOrder;
            $scope.pageSize1 = q.pagination_1.pageSize;
            $scope.currentPage1 = q.pagination_1.currentPage;
            $scope.reverseOrder1 = reverseOrder1;
            $scope.sortField1 = q.pagination_1.sortField;


            getPickUpPlaces();

        }, 0);

        $scope.pageChangeHandler = function (page, size, pagination_id) {
            $scope.currentPage1 = page;
            $scope.pageSize1 = size;

            $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['currentPage'] = page;
            $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['pageSize'] = size;
        };
        $scope.sortBy1 = function(sortField) {
            $scope.reverseOrder1 = ($scope.sortField1 === sortField) ? !$scope.reverseOrder1 : false;
            $scope.sortField1 = sortField;
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['sortField'] = sortField;
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['reverseOrder'] = $scope.reverseOrder1;
        };

        var getPickUpPlaces = function () {
            PickUpPlacesServices.getPickUpPlaces().then(function (response) {
                console.log(response);
                $scope.pickUpPlacesList = response.result;
            })
        };

        $scope.deletePickUpPlace = function (place_id) {
            console.log({
                warehouse_id: [place_id]
            });
            PickUpPlacesServices.deletePickUpPlace({
                warehouse_ids: place_id
            }).then(function (response) {
                getPickUpPlaces();
            });
        }


    }]);