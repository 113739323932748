angular.module('appNotificationHandler', [])
    .directive("notificationHandling", ['$timeout', function($timeout) {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                elem.bind("click",function() {
                    elem.remove();
                });
                $timeout(function () {
                    elem.remove();
                }, 3000);
            }
        }
    }]);