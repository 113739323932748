/* LOGIN CONTROLLER */
angular.module('LoginCtrl', [])
    .controller("LoginCtrl", ["$scope", "$location", "Auth", "$localStorage", "$rootScope", "$window", function($scope, $location, Auth, $localStorage, $rootScope, $window) {

        $scope.loginEmail = '';
        $scope.loginPassword = '';
        $scope.login = function() {
            Auth.login({
                email: $scope.loginEmail,
                password: $scope.loginPassword
            }).then(function () {
                $location.path("/orders");
                // $localStorage.pathBefore ? $location.path($localStorage.pathBefore) : $location.path("/orders");
                // $localStorage.catAndSubcat = [];
                // To refresh the page
                $rootScope.$broadcast('userLoggedIn', Auth.isLoggedIn());
            })
        };

    }]);