angular.module('appDatepickerOptionsOrder', [])
    .directive("datepickerOptionsOrder", ['$http', '$localStorage', '$timeout', '$compile', '$rootScope', function($http, $localStorage, $timeout, $compile, $rootScope) {
        return {
            restrict: "A",
            link: function(scope, elem, attrs) {
                $rootScope.$on('$translateChangeSuccess', function(event, current, previous) {});
                scope.filter_date_range = {
                    date: {
                        startDate: $localStorage.advanced_filters_data_order ? $localStorage.advanced_filters_data_order.filter.dateSelected.startDate : moment(),
                        endDate: $localStorage.advanced_filters_data_order ? $localStorage.advanced_filters_data_order.filter.dateSelected.endDate : moment()
                    },
                    options: {
                        alwaysShowCalendars: true,
                        showDropdowns: true,
                        timePicker: true,
                        timePicker24Hour: true,
                        timePickerIncrement: 10,
                        showCustomRangeLabel: false,
                        parentEl: '.advanced-filters .server-side-filters',
                        buttonClasses: "btn btn-sm datepicker-btns",
                        applyButtonClasses: "btn-primary datepicker-apply",
                        cancelClass: "btn-default datepicker-cancel",
                        opens: "right",
                        locale: {
                            format: "YYYY-MM-DD",
                            separator: " - ",
                            applyLabel: "Применить фильтр",
                            cancelLabel: "Отмена",
                            fromLabel: "From",
                            toLabel: "To",
                            customRangeLabel: "Custom",
                            weekLabel: "W",
                            daysOfWeek: [
                                "вс",
                                "пн",
                                "вт",
                                "ср",
                                "чт",
                                "пт",
                                "сб"
                            ],
                            monthNames: [
                                "Январь",
                                "Февраль",
                                "Март",
                                "Апрель",
                                "Май",
                                "Июнь",
                                "Июль",
                                "Август",
                                "Сентябрь",
                                "Октябрь",
                                "Ноябрь",
                                "Декабрь"
                            ],
                            firstDay: 1
                        },
                        ranges: {
                            'Сегодня': [moment(), moment()],
                            'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
                            'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
                            'С начала месяца': [moment().startOf('month'), moment().endOf('month')],
                            'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'За все время': [moment("2018-08-01" ,"YYYY-MM-DD"), moment()]
                        },
                        eventHandlers: {
                            'apply.daterangepicker': function (ev, picker) {
                                /*scope.filter_date_range3.date.startDate.format('YYYY-MM-DD');
                                scope.filter_date_range3.date.endDate.format('YYYY-MM-DD');*/
                                $localStorage.advanced_filters_data_order['filter']['dateSelected'] = {
                                    startDate: scope.filter_date_range.date.startDate.format('YYYY-MM-DD'),
                                    endDate: scope.filter_date_range.date.endDate.format('YYYY-MM-DD')
                                };
                                scope.currentPage1 = 1;
                                scope.getOrders(scope.currentPage1, scope.pageSize1, scope.sortField, scope.reverseOrder);
                            }
                        }
                    }
                };
            }
        }
    }]);