angular.module('SettingsServices', [])

    .service("SettingsServices", ["$http", '$location', "$route", "$localStorage", "$rootScope", 'GlobalNotificationService', 'API', 'Upload', function($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API, Upload) {

        /* 'GET' REQUEST TO GET LIST OF PICK UP PLACES */
        this.getSupportSettings = function (data) {
            return $http({
                method: 'GET',
                url: API.url + 'support',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };

        /* 'POST' REQUEST TO SET PICK UP PLACES */
        this.updateSupportSettings = function (data) {
            return $http({
                method: 'POST',
                url: API.url + 'support',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    support_id: data.support_id,
                    phone_number: data.phone_number,
                    email: data.email
                }
            }).then(function successCallback(response) {
                GlobalNotificationService.trigger('Вы успешно изменили информацию', 'success');
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };

    }]);

