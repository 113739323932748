angular.module('SharedService', [])

    .service("SharedService", ["$http", '$location', "$route", "$localStorage", "$rootScope", 'GlobalNotificationService', 'API', '$timeout', function($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API, $timeout) {

        /* 'POST' REQUEST TO SEND PUSH NOTIFICATION */
        this.sendPushNotification = function (data) {
            return $http({
                method: 'POST',
                url: API.url + 'push',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                data: {
                    userId: $localStorage.user.id,
                    message: data.message,
                    user_ids: data.user_ids ? data.user_ids.toString() : '',
                    discount_company_id: data.discount_company_id ? data.discount_company_id : '',
                    type: data.type
                }
            }).then(function successCallback(response) {
                console.log(response);
                GlobalNotificationService.trigger('Push-сообщение отправлено', 'success');
                return response.data;
            }, function errorCallback(response) {
                GlobalNotificationService.trigger(response.status, 'error');
            });
        };

        /* 'GET' REQUEST TO GET LIST OF CAMPAIGNS */
        this.getBrands = function (data) {
            return $http({
                method: 'GET',
                url: API.url + 'spareparts/brands',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    type: data.type,
                    brand_name: data.brand_name
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                /*if (response.status === 401) {
                    $location.path('/login');
                }*/
            });
        };


    }]);