angular.module('PlacesEditCtrl', [])
    .controller("PlacesEditCtrl", ["$scope", "$http", "Auth", "NgMap", "$rootScope","$location", "$localStorage", "$routeParams", "APP_INFO", "$timeout", "PickUpPlacesServices", "GlobalNotificationService", "FilterService", function($scope, $http, Auth, NgMap, $rootScope, $location, $localStorage, $routeParams, APP_INFO, $timeout, PickUpPlacesServices, GlobalNotificationService, FilterService) {

        $("form input").on("keypress", function(e) {
            return e.keyCode !== 13;
        });

        $scope.goBack = function () {
            $location.path('/pick-up-places');
        };

        var getSinglePlace = function () {
            PickUpPlacesServices.getSinglePickUpPlace({
                warehouse_id: $routeParams.place_id
            }).then(function (response) {
                console.log(response);
                $scope.singlePlaceData = response.result[0];
            })
        };
        getSinglePlace();

        /*NgMap.getMap().then(function(map) {
            $scope.map = map;
        });*/

        $scope.markerPosition = 'current-position';
        $scope.placeChanged = function() {
            $scope.place = this.getPlace();
            $scope.validationCheck();
            $scope.singlePlaceData.location[0].description = $scope.place.formatted_address;
            $scope.singlePlaceData.location[0].place_id = $scope.place.place_id;
            console.log($scope.singlePlaceData);
            /*$scope.map.setCenter($scope.place.geometry.location);
            $scope.markerPosition = $scope.place.formatted_address;*/
        };


        $scope.validationCheck = function() {
            if ($scope.place === undefined) {
                $scope.places_edit.place_address.$setValidity("size", false);
            } else {
                $scope.places_edit.place_address.$setValidity("size", true);
            }
        };

        $scope.savePlace = function () {
            console.log({
                warehouse_id: $routeParams.place_id,
                description: $scope.singlePlaceData.description,
                phone_number: $scope.singlePlaceData.phone_number,
                mode_data: $scope.singlePlaceData.mode_data,
                place_id: $scope.singlePlaceData.location[0].place_id
            });
           PickUpPlacesServices.createEditPickUpPlaces({
               warehouse_id: $routeParams.place_id,
               description: $scope.singlePlaceData.description,
               phone_number: $scope.singlePlaceData.phone_number,
               mode_data: $scope.singlePlaceData.mode_data,
               place_id: $scope.singlePlaceData.location[0].place_id
           }).then(function (response) {
                console.log(response.status);
                if (response.status === 'success') {
                    GlobalNotificationService.trigger('Пункт выдачи успешно изменен', 'success');
                }
                $scope.goBack();
           })
        };

    }]);