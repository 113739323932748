angular.module('CampaignsCtrl', [
    'CampaignsAddCtrl',
    'CampaignsEditCtrl'
])
    .controller("CampaignsCtrl", ["$scope", "$http", "Auth", "$rootScope","$location", "$localStorage", "APP_INFO", "$timeout", "CampaignsService", "FilterService", "SharedService", function($scope, $http, Auth, $rootScope, $location, $localStorage, APP_INFO, $timeout, CampaignsService, FilterService, SharedService) {

        $scope.onPagePaginations = 1;

        $scope.push = {
            message: ''
        };
        $scope.comp_id = [];
        var campaingIdForPush = '';

        $scope.loadingFilterIndicator = false;
        $scope.filter_was_changed = false;

        $scope.filterDataCampaigns = {
            filterRegionsData: [],
            filterSegmentsData: []
        };
        $scope.filterSelectedCampaigns = {
            filterRegionsSelected: [],
            filterSegmentsSelected: []
        };

        $scope.filters = {
            filterRegionsTranslate: {
                checkAll: 'Все',
                uncheckAll: 'Удалить все',
                searchPlaceholder: 'Поиск...',
                buttonDefaultText: 'Регионы'
            },
            filterRegionsSettings: {
                enableSearch: true,
                smartButtonMaxItems: 2,
                scrollableHeight: 'auto',
                scrollable: true,
                keyboardControls: true,
                styleActive: true,
                show: true,
                idProperty: 'id',
                smartButtonTextConverter: function(itemText, originalItem) {
                    return itemText;
                }
            },
            filterRegionsEvents: {
                onItemSelect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onItemDeselect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onSelectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                },
                onDeselectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                }
            },

            filterSegmentsTranslate: {
                checkAll: 'Все',
                uncheckAll: 'Удалить все',
                searchPlaceholder: 'Поиск...',
                buttonDefaultText: 'Сегменты'
            },
            filterSegmentsSettings: {
                enableSearch: false,
                smartButtonMaxItems: 2,
                scrollableHeight: 'auto',
                scrollable: true,
                keyboardControls: true,
                styleActive: true,
                show: true,
                idProperty: 'id',
                smartButtonTextConverter: function(itemText, originalItem) {
                    return itemText;
                }
            },
            filterSegmentsEvents: {
                onItemSelect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onItemDeselect: function (item) {
                    $scope.filter_was_changed = true;
                },
                onSelectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                },
                onDeselectAll: function (item) {
                    $timeout(function () {
                        $scope.filter_was_changed = true;
                    })
                }
            }
        };

        angular.element("#sendPushNotification").on('hidden.bs.modal', function(){
            $scope.push = {
                message: ''
            };
        });

        $timeout(function () {


            /*if ($localStorage.filterSelectedCampaigns) {
                $scope.filterSelectedCampaigns = JSON.parse(JSON.stringify($localStorage.filterSelectedCampaigns));
            };*/

            $scope.filterDataCampaigns.filterSegmentsData = [
                {
                    id: 1,
                    label: 'СТО'
                }, {
                    id: 2,
                    label: 'Магазин'
                }, {
                    id: 3,
                    label: 'Автопарк'
                }, {
                    id: 4,
                    label: 'Владелец автомобиля'
                }
            ];


            /*if (!$localStorage.filterSelectedCampaigns) {
                $localStorage.filterSelectedCampaigns = $scope.filterSelectedCampaigns;
            } else {
                $scope.filterSelectedCampaigns = $localStorage.filterSelectedCampaigns;
            }*/

            if ($localStorage.filterSelectedCampaigns) {
                $scope.filterSelectedCampaigns = JSON.parse(JSON.stringify($localStorage.filterSelectedCampaigns));
            };

            var q = $localStorage.pagination_state[$rootScope.pagination_id];
            var reverseOrder1 = (q.pagination_1.reverseOrder === null) ? true : q.pagination_1.reverseOrder;
            $scope.pageSize1 = q.pagination_1.pageSize;
            $scope.currentPage1 = q.pagination_1.currentPage;
            $scope.reverseOrder1 = reverseOrder1;
            $scope.sortField1 = q.pagination_1.sortField;


            getCampaigns();

        }, 0);

        $scope.pageChangeHandler = function (page, size, pagination_id) {
            $scope.currentPage1 = page;
            $scope.pageSize1 = size;

            $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['currentPage'] = page;
            $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['pageSize'] = size;
        };
        $scope.sortBy1 = function(sortField) {
            $scope.reverseOrder1 = ($scope.sortField1 === sortField) ? !$scope.reverseOrder1 : false;
            $scope.sortField1 = sortField;
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['sortField'] = sortField;
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['reverseOrder'] = $scope.reverseOrder1;
        };

        var getRegions = function () {
            FilterService.getRegions().then(function (response) {
                console.log(response);
                //$scope.regionsList = response.result;
                for (var i = 0; i < response.data.result.length; i++) {
                    $scope.filterDataCampaigns.filterRegionsData.push({
                        id: response.data.result[i].id,
                        label: response.data.result[i].description
                    })
                }
            })
        };
        getRegions();

        var resetForm = function (form) {
            form.$setUntouched();
            form.$setPristine();
        };

        var postStructure = function () {
            var filter = $scope['filterSelectedCampaigns'];
            //var filter_date = $scope['filter_date_range'];
            var toSend = {
                region_ids: [],
                segment_ids: []
            };

            for (var w = 0; w < filter.filterRegionsSelected.length; w++) {
                toSend.region_ids.push(filter.filterRegionsSelected[w].id);
            }

            for (var w = 0; w < filter.filterSegmentsSelected.length; w++) {
                toSend.segment_ids.push(filter.filterSegmentsSelected[w].id);
            }

            return toSend;

        };

        var getCampaigns = function () {
            var toSend = postStructure();
            console.log({
                discount_company_id: null,
                regions: toSend.region_ids,
                segments: toSend.segment_ids
            });
            CampaignsService.getCampaigns({
                discount_company_id: null,
                regions: toSend.region_ids,
                segments: toSend.segment_ids
            }).then(function (response) {
                for (var i = 0; i < response.result.length; i++) {
                    response.result[i].is_active === 1 ? response.result[i].is_active = true : response.result[i].is_active = false;
                }
                $scope.campaignList = response.result;

                $timeout(function () {
                    $scope.loadingFilterIndicator = false;
                    $scope.filter_was_changed = false;
                }, 0);

                for (var key in $scope.filterSelectedCampaigns) {
                    if ($scope.filterSelectedCampaigns[key].length) {
                        $scope.filterReadyForClear = true;
                        return false;
                    } else {
                        $scope.filterReadyForClear = false;
                    }
                };
            });
        };

        /*var statusCheck = function (campaign_id) {
            for (var i = 0; i < $scope.campaignList.length; i++) {
                if ($scope.campaignList[i].id === campaign_id) {
                    $scope.campaignList[i]['is_active'] = 0;
                }
            }
        };*/
        $scope.toggleCampaign = function (campaign) {
            campaingIdForPush = '';
            campaingIdForPush = +campaign.id;
            console.log({
                discount_company_id: campaign.id,
                discount_company_status: campaign.is_active ? 1 : 0
            });
            CampaignsService.updateCampaignsStatus({
                discount_company_id: campaign.id,
                discount_company_status: campaign.is_active ? 1 : 0
            }).then(function (response) {
                console.log(response);
                if (response.result.is_active === 1) {
                    angular.element('#sendPushNotification').modal('show');
                }
                getCampaigns();
            });

        };

        $scope.deleteCampaignModalShow = function (campaign) {
            $scope.comp_id = [];
            $scope.comp_id.push(campaign.id);
            angular.element('#confirmModal').modal('show');
        };

        $scope.deleteCampaigns = function (campaign) {
            CampaignsService.deleteCampaigns({
                company_ids: $scope.comp_id
            }).then(function () {
                $scope.comp_id = [];
                angular.element('#confirmModal').modal('hide');
                getCampaigns();
            });
        };

        $scope.goToEdit = function (campaign) {
            $location.path('/campaigns/edit/' + campaign.id);
        };

        $scope.sendPushNotification = function (message, form) {
            $scope.loadingIndicator = true;
            console.log({
                message: message,
                discount_company_id: campaingIdForPush,
                type: 2
            });
            SharedService.sendPushNotification({
                message: message,
                discount_company_id: campaingIdForPush,
                type: 2
            }).then(function (res) {
                angular.element('#sendPushNotification').modal('hide');
                campaingIdForPush = '';
                $scope.push = {
                    message: ''
                };
                resetForm(form);
                $timeout(function () {
                    $scope.loadingIndicator = false;
                });
            })
        };

        var resetForm = function (form) {
            form.$setUntouched();
            form.$setPristine();
        };

        $scope.resetFilter = function () {
            $scope.loadingFilterIndicator = true;
            $scope.filterReadyForClear = false;
            $scope.filterSelectedCampaigns = {
                filterRegionsSelected: [],
                filterSegmentsSelected: []
            };
            $localStorage.filterSelectedCampaigns = $scope.filterSelectedCampaigns;
            getCampaigns();
        };

        $scope.acceptFilter = function () {
            $scope.loadingFilterIndicator = true;
            $localStorage.filterSelectedCampaigns = $scope.filterSelectedCampaigns;
            getCampaigns();
        };

    }]);