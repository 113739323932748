angular.module('PartsRatingCtrl', [])
    .controller("PartsRatingCtrl", ["$scope", "$http", "Auth", "$rootScope","$location", "$localStorage", "APP_INFO", "$timeout", "FilterService", "PartsRatingService", function($scope, $http, Auth, $rootScope, $location, $localStorage, APP_INFO, $timeout, FilterService, PartsRatingService) {

        $scope.partsRatingList = [];
        $scope.onPagePaginations = 1;

        $scope.loadingFilterIndicator = false;
        $scope.filter_was_changed = false;

        /*$scope.filterDataPartsRating = {
            filterRegionsData: []
        };
        $scope.filterSelectedPartsRating = {
            filterRegionsSelected: []
        };*/

        $scope.filter_date_range = {
            date: {
                startDate: $localStorage.advanced_filters_data_parts_rating ? $localStorage.advanced_filters_data_parts_rating.filter.dateSelected.startDate : moment(),
                endDate: $localStorage.advanced_filters_data_parts_rating ? $localStorage.advanced_filters_data_parts_rating.filter.dateSelected.endDate : moment()
            },
            options: {
                alwaysShowCalendars: true,
                showDropdowns: true,
                timePicker: true,
                timePicker24Hour: true,
                timePickerIncrement: 10,
                showCustomRangeLabel: false,
                parentEl: '.server-side-filters',
                buttonClasses: "btn btn-sm datepicker-btns",
                applyButtonClasses: "btn-primary datepicker-apply",
                cancelClass: "btn-default datepicker-cancel",
                opens: "right",
                locale: {
                    format: "DD-MM-YYYY",
                    separator: " - ",
                    applyLabel: "Применить фильтр",
                    cancelLabel: "Отмена",
                    fromLabel: "From",
                    toLabel: "To",
                    customRangeLabel: "Custom",
                    weekLabel: "W",
                    daysOfWeek: [
                        "вс",
                        "пн",
                        "вт",
                        "ср",
                        "чт",
                        "пт",
                        "сб"
                    ],
                    monthNames: [
                        "Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"
                    ],
                    firstDay: 1
                },
                ranges: {
                    'Сегодня': [moment(), moment()],
                    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
                    'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
                    'С начала месяца': [moment().startOf('month'), moment().endOf('month')],
                    'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'За все время': [moment("2018-08-01" ,"YYYY-MM-DD"), moment()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev, picker) {
                        /*$localStorage.advanced_filters_data_parts_rating.filter['dateSelected'] = {
                            startDate: $scope.filter_date_range.date.startDate.format('YYYY-MM-DD'),
                            endDate: $scope.filter_date_range.date.endDate.format('YYYY-MM-DD')
                        };
                        $scope.currentPage1 = 1;
                        $scope.getPartsRating($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);*/
                    }
                }
            }
        };
        $scope.filters = {
            filterRegionsTranslate: {
                checkAll: 'Все',
                uncheckAll: 'Удалить все',
                searchPlaceholder: 'Поиск...',
                buttonDefaultText: 'Регионы'
            },
            filterRegionsSettings: {
                enableSearch: true,
                smartButtonMaxItems: 2,
                scrollableHeight: 'auto',
                scrollable: true,
                keyboardControls: true,
                styleActive: true,
                show: true,
                idProperty: 'id',
                smartButtonTextConverter: function(itemText, originalItem) {
                    return itemText;
                }
            },
            filterRegionsEvents: {
                onItemSelect: function (item) {
                    $scope.currentPage1 = 1;
                    $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);
                },
                onItemDeselect: function (item) {
                    $scope.currentPage1 = 1;
                    $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);
                },
                onSelectAll: function (item) {
                    $timeout(function () {
                        $scope.currentPage1 = 1;
                        $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);
                    })
                },
                onDeselectAll: function (item) {
                    $timeout(function () {
                        $scope.currentPage1 = 1;
                        $scope.getRegisteredUsers($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);
                    })
                }
            }
        };

        /*** START:: PAGINATION STATE CONTROLLING FUNCTIONS */
        /* TIMEOUT IS USED TO ENABLE THE FUNCTIONS AND ASSIGNMENTS INITIATE AFTER DOM IS RENDERED AND TO PREVENT ERRORS OF TYPE 'undefined' */
        $timeout(function () {

            if (!$localStorage.advanced_filters_data_parts_rating) {
                $localStorage.advanced_filters_data_parts_rating = {};
                $localStorage.advanced_filters_data_parts_rating['filter'] = {
                    dateSelected: {
                        startDate: $scope.filter_date_range.date.startDate.format('YYYY-MM-DD'),
                        endDate: $scope.filter_date_range.date.endDate.format('YYYY-MM-DD')
                    }
                };
            }

            /*if (!$localStorage.filterSelectedUser) {
                $localStorage.filterSelectedUser = $scope.filterSelectedUser;
            } else {
                $scope.filterSelectedUser = $localStorage.filterSelectedUser;
            }*/

            var q = $localStorage.pagination_state[$rootScope.pagination_id];
            var reverseOrder1 = (q.pagination_1.reverseOrder === null) ? false : q.pagination_1.reverseOrder;
            $scope.pageSize1 = q.pagination_1.pageSize;
            $scope.currentPage1 = q.pagination_1.currentPage;
            $scope.sortField = q.pagination_1.sortField || 'username';
            $scope.reverseOrder = reverseOrder1;

            $scope.getPartsRating($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);

        }, 0);

        /* ON PAGE CHANGE HANDLER SETS A NEW PAGE AND A PAGE SIZE PARAMETERS TO SPECIFIC OBJECT IN localstorage.pagination_state  */
        $scope.pageSizeHandler = function (size, pagination_id) {
            $scope.pageSize1 = size;
            $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['pageSize'] = size;
            $scope.getPartsRating($scope.currentPage1, size, $scope.sortField, $scope.reverseOrder);
        };
        $scope.pageChangeHandler = function (page, old_page, size, pagination_id) {
            console.log(page);
            console.log(old_page);
            console.log(size);
            if (page !== old_page) {
                $scope.currentPage1 = page;
                $scope.pageSize1 = size;
                $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['currentPage'] = page;
                $localStorage.pagination_state[$rootScope.pagination_id][pagination_id]['pageSize'] = size;
                $scope.getPartsRating(page, size, $scope.sortField, $scope.reverseOrder);
            }
        };

        /* WHEN SORT FUNCTION TRIGGERED, HANDLER SETS A NEW SOFT FIELD DATA AND SORT ORDER PARAMETERS TO SPECIFIC OBJECT IN localstorage.pagination_state  */
        $scope.sortBy = function(sortField) {
            $scope.reverseOrder = ($scope.sortField === sortField) ? !$scope.reverseOrder : false;
            $scope.sortField = sortField;
            $scope.getPartsRating($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['sortField'] = sortField;
            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_1']['reverseOrder'] = $scope.reverseOrder;
        };
        /*** END:: PAGINATION STATE CONTROLLING FUNCTIONS */


        var postStructure = function () {
            var filter = $scope['filterSelectedPartsRating'];
            var filter_date = $scope['filter_date_range'];
            var toSend = {
                region_ids: [],
                date_from: (moment(filter_date.date.startDate, 'YYYY-MM-DD HH:mm a ', true).isValid()) ? filter_date.date.startDate.format('YYYY-MM-DD') : filter_date.date.startDate,
                date_to: (moment(filter_date.date.endDate, 'YYYY-MM-DD HH:mm a ', true).isValid()) ? filter_date.date.endDate.format('YYYY-MM-DD') : filter_date.date.endDate
            };

            /*for (var w = 0; w < filter.filterRegionsSelected.length; w++) {
                toSend.region_ids.push(filter.filterRegionsSelected[w].id);
            }*/

            return toSend;
        };
        $scope.getPartsRating = function (page, per_page, order_by, sort) {

            var toSent = postStructure();

            PartsRatingService.getPartsRating({
                date_start: toSent.date_from,
                date_end: toSent.date_to,
                page: page,
                per_page: per_page,
                order_field: order_by,
                order: sort ? 'desc' : 'asc'
            }).then(function (response) {
                console.log(response);
                /*for (var i = 0; i < response.result.length; i++) {
                    if (response.result[i].created_at) {
                        response.result[i].created_at.date = moment(response.result[i].created_at.date).format("YYYY-MM-DD HH:mm:ss");
                    }
                }*/
                $scope.partsRatingList = response.result;
                $scope.partsRatingCount = response.count;

                $timeout(function () {
                    $scope.loadingFilterIndicator = false;
                    $scope.filter_was_changed = false;
                }, 0);
            })
        };

        $scope.acceptFilter = function () {
            $scope.loadingFilterIndicator = true;
            $localStorage.advanced_filters_data_parts_rating['filter'].dateSelected = {
                startDate: moment($scope.filter_date_range.date.startDate).format('YYYY-MM-DD'),
                endDate: moment($scope.filter_date_range.date.endDate).format('YYYY-MM-DD')
            };
            $scope.currentPage1 = 1;
            $scope.getPartsRating($scope.currentPage1, $scope.pageSize1, $scope.sortField, $scope.reverseOrder);
        };

        $scope.$watchGroup(['filter_date_range.date'], function(newV, oldV){
            if (newV !== oldV) {
                $scope.filter_was_changed = true;
            }
        }, true);


    }]);