angular.module('FilterService', [])

    .service("FilterService", ["$http", '$location', "$route", "$localStorage", "$rootScope", 'GlobalNotificationService', 'API', '$timeout', function($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API, $timeout) {

        /* 'GET' REQUEST TO GET LIST OF REGIONS REGISTERED */
        this.getRegions = function () {
            return $http({
                method: 'GET',
                url: API.url +  'location/region',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response;
            }, function errorCallback(response) {
                console.log(response);
            });
        };


    }]);