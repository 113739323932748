angular.module("PricingService", [])

    .service("PricingService", ["$http", "$location", "$route", "$localStorage", "$rootScope", "GlobalNotificationService", "API", function ($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API) {

        /*'GET' request to get orders*/
        this.getDiscountsData = function () {
            return $http({
                method: 'GET',
                url: API.url + 'app/discount',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id
                }
            }).then(function successCallback(response) {
                console.log(response);
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                if (response.status === 401) {
                    $location.path('/login');
                }
            });
        };

        /*'POST' request to edit discounts*/
        this.editDiscounts = function (data) {
            return $http({
                method: 'POST',
                url: API.url + 'app/discount',
                headers: {
                    Authorization: $localStorage.user.api_key
                },
                params: {
                    userId: $localStorage.user.id,
                    discount_id: data.discount_id,
                    markup: data.markup,
                    discount: data.discount
                }
            }).then(function successCallback(response) {
                console.log(response);
                GlobalNotificationService.trigger('Данные сохранены', 'success');
                return response.data;
            }, function errorCallback(response) {
                console.log(response);
                if (response.status === 401) {
                    $location.path('/login');
                }
            });
        };


    }] )