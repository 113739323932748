angular.module('PlacesAddCtrl', [])
    .controller("PlacesAddCtrl", ["$scope", "$http", "Auth", "NgMap", "$rootScope","$location", "$localStorage", "APP_INFO", "$timeout", "PickUpPlacesServices", "GlobalNotificationService", "FilterService", function($scope, $http, Auth, NgMap, $rootScope, $location, $localStorage, APP_INFO, $timeout, PickUpPlacesServices, GlobalNotificationService, FilterService) {

        $scope.goBack = function () {
            $location.path('/pick-up-places');
        };

        $("form input").on("keypress", function(e) {
            return e.keyCode !== 13;
        });

        /*NgMap.getMap().then(function(map) {
            $scope.map = map;
        });*/

        $scope.markerPosition = 'current-position';
        $scope.placeChanged = function() {
            $scope.place = this.getPlace();
            $scope.validationCheck();
            /*$scope.map.setCenter($scope.place.geometry.location);
            $scope.markerPosition = $scope.place.formatted_address;*/
        };


        $scope.validationCheck = function() {
            console.log($scope.place);
            if ($scope.place === undefined) {
                $scope.places_add.place_address.$setValidity("size", false);
            } else {
                $scope.places_add.place_address.$setValidity("size", true);
            }
        };

        $scope.savePlace = function () {
            console.log({
                description: $scope.place_description,
                phone_number: $scope.place_phone_number,
                mode_data: $scope.place_mode_data,
                place_id: $scope.place.place_id
            });
            PickUpPlacesServices.createEditPickUpPlaces({
                description: $scope.place_description,
                phone_number: $scope.place_phone_number,
                mode_data: $scope.place_mode_data,
                place_id: $scope.place.place_id
            }).then(function (response) {
                console.log(response.status);
                if (response.status === 'success') {
                    GlobalNotificationService.trigger('Пункт выдачи успешно создан', 'success');
                }
                $scope.goBack();
            })
        };


    }]);