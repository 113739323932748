angular.module('PricingCtrl', [])
    .controller("PricingCtrl", ["$scope", "$http", "Auth", "$rootScope","$location", "$localStorage", "APP_INFO", "$timeout", "PricingService", function($scope, $http, Auth, $rootScope, $location, $localStorage, APP_INFO, $timeout, PricingService) {

        $scope.loadingIndicator = false;

        var getDiscounts = function () {
            PricingService.getDiscountsData().then(function (response) {
                console.log(response);
                $scope.discountsData = response.result;
                $scope.discountType1 = {
                    discount: +response.result[0].discount,
                    markup: +response.result[0].markup
                };
                $scope.discountType2 = {
                    discount: +response.result[1].discount,
                    markup: +response.result[1].markup
                };
                $scope.discountType3 = {
                    discount: +response.result[2].discount,
                    markup: +response.result[2].markup
                };
                $scope.discountType4 = {
                    discount: +response.result[3].discount,
                    markup: +response.result[3].markup
                };
            })
        };
        getDiscounts();

        $scope.editDiscountModal = function (id) {
            if (id === 1) {
                $scope.discount = $scope.discountType1.discount;
                $scope.markup = $scope.discountType1.markup;
            } else if (id === 2) {
                $scope.discount = $scope.discountType2.discount;
                $scope.markup = $scope.discountType2.markup;
            } else if (id === 3) {
                $scope.discount = $scope.discountType3.discount;
                $scope.markup = $scope.discountType3.markup;
            } else if (id === 4) {
                $scope.discount = $scope.discountType4.discount;
                $scope.markup = $scope.discountType4.markup;
            }
            $scope.discountID = id;

            angular.element('#editDiscounts').modal('show');

        };

        $scope.editDiscounts = function (discount, markup) {

            $scope.loadingIndicator = true;
            PricingService.editDiscounts({
                discount_id: $scope.discountID,
                markup: markup,
                discount:discount
            }).then(function (response) {
                console.log(response);
                $scope.loadingIndicator = false;
                angular.element('#editDiscounts').modal('hide');
                getDiscounts();
            })
        };
        
    }]);