angular.module("OrdersService", [])

.service("OrdersService", ["$http", "$location", "$route", "$localStorage", "$rootScope", "GlobalNotificationService", "API", function ($http, $location, $route, $localStorage, $rootScope, GlobalNotificationService, API) {

    /*'GET' request to get orders*/
    this.getOrders = function (data) {
        return $http({
            method: 'GET',
            url: API.url + 'order',
            headers: {
                Authorization: $localStorage.user.api_key
            },
            params: {
                userId: $localStorage.user.id,
                //all: 1,
                date_start: data.date_start,
                date_end: data.date_end,
                payment_status: data.payment_status ? data.payment_status.toString() : '',
                status: data.status ? data.status.toString() : '',
                page: data.page,
                per_page: data.per_page,
                order_field: data.order_by,
                order: data.sort,
                search_string: data.search_string
            }
        }).then(function successCallback(response) {
            console.log(response);
            return response.data;
        }, function errorCallback(response) {
            console.log(response);
            if (response.status === 401) {
                $location.path('/login');
            }
        });
    };

    /*'GET' request to get single order*/
    this.getSingleOrder = function (id) {
        return $http({
            method: 'GET',
            url: API.url + 'order',
            headers: {
                Authorization: $localStorage.user.api_key
            },
            params: {
                userId: $localStorage.user.id,
                order_id: id
            }
        }).then(function successCallback(response) {
            console.log(response);
            return response.data;
        }, function errorCallback(response) {
            console.log(response);
            if (response.status === 401) {
                $location.path('/login');
            }
        });
    };

    /*'PUT' request to edit single order*/
    this.editSingleOrder = function (data) {
        return $http({
            method: 'PUT',
            url: API.url + 'order',
            headers: {
                Authorization: $localStorage.user.api_key
            },
            data: {
                userId: $localStorage.user.id,
                order_id: data.order_id,
                address: data.address,
                username: data.username,
                phone_number: data.phone_number,
                order_items: data.order_items,
                status: data.status ? data.status : 0,
                location_id_from: data.location_id_from
            }
        }).then(function successCallback(response) {
            GlobalNotificationService.trigger('Данные сохранены', 'success');
            console.log(response);
            return response.data;
        }, function errorCallback(response) {
            console.log(response);
            if (response.status === 401) {
                $location.path('/login');
            }
        });
    };
//editSingleOrderStatus

    /*'PUT' request to edit status*/
    this.editSingleOrderStatus = function (data) {
        return $http({
            method: 'PUT',
            url: API.url + 'order',
            headers: {
                Authorization: $localStorage.user.api_key
            },
            data: {
                userId: $localStorage.user.id,
                order_id: data.order_id,
                status: data.status
            }
        }).then(function successCallback(response) {
            GlobalNotificationService.trigger('Статус изменен', 'success');
            console.log(response);
            return response.data;
        }, function errorCallback(response) {
            console.log(response);
            if (response.status === 401) {
                $location.path('/login');
            }
        });
    };
    /*'PUT' request to add product*/
    this.addProductItem = function (data) {
        return $http({
            method: 'PUT',
            url: API.url + 'order',
            headers: {
                Authorization: $localStorage.user.api_key
            },
            data: {
                userId: $localStorage.user.id,
                order_id: data.order_id,
                order_items: [{
                    article: data.article,
                    part_name: data.part_name,
                    price: data.price.toString(),
                    discount_price: data.discount_price.toString(),
                    speed_stat: data.speed_stat.toString(),
                    quantity: data.quantity
                }]
            }
        }).then(function successCallback(response) {
            GlobalNotificationService.trigger('Товар добавлен', 'success');
            console.log(response);
            return response.data;
        }, function errorCallback(response) {
            console.log(response);
            if (response.status === 401) {
                $location.path('/login');
            }
        });
    };

    /* 'DELETE' REQUEST TO DELETE PRODUCT ITEMS FROM ITEMS LIST */
    this.deleteItems = function (data) {
        return $http({
            method: 'DELETE',
            url: API.url + 'order',
            headers: {
                Authorization: $localStorage.user.api_key
            },
            params: {
                userId: $localStorage.user.id,
                order_id: data.order_id,
                order_items_id: data.order_items_id
            }
        }).then(function successCallback(response) {
            GlobalNotificationService.trigger('Товар удален', 'success');
            console.log(response);
            return response;
        }, function errorCallback(response) {
            console.log(response);
            GlobalNotificationService.trigger(response.statusText, 'error');
            return response;
        });
    };



}] )