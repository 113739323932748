angular.module('NotificationsServices', [])

    .service("GlobalNotificationService", ['$compile', '$rootScope', '$timeout', function($compile ,$rootScope, $timeout) {

        /*** IF SERVICE IS TRIGGERED IN CHECKS FOR A SECOND PARAMETER AND ACCORDINGLY CREATES A DIV WITH A MESSAGE OF THE ERROR / SUCCESS  */
        this.trigger = function(content, state) {
            if (state === 'success') {
                angular.element(document.getElementById('notificationArea')).append($compile("<div class='notification alert alert-success' notification-handling>" + content +"</div>")($rootScope));
            } else if (state === 'error') {
                angular.element(document.getElementById('notificationArea')).append($compile("<div class='notification alert alert-danger' notification-handling>" + content +"</div>")($rootScope));
            }
        };
    }]);