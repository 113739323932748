angular.module('CampaignsEditCtrl', [])
    .controller("CampaignsEditCtrl", ["$scope", "$http", "Auth", "$window", "$rootScope", "$location", "$routeParams", "$q", "Upload", "$localStorage", "APP_INFO", "$timeout", "CampaignsService", "GlobalNotificationService", "SharedService", function($scope, $http, Auth, $window, $rootScope, $location, $routeParams, $q, Upload, $localStorage, APP_INFO, $timeout, CampaignsService, GlobalNotificationService, SharedService) {

        $scope.selectedCampaignType = {
            selected: ''
        };
        $scope.campaigns_period_from = moment($rootScope.currentDate).format('YYYY-MM-DD');
        $scope.dateOptions = {
            changeYear: true,
            changeMonth: true,
            dateFormat: "dd-mm-yy"
        };
        $scope.tagHandler = function (tag){
            return { name: tag }
        };

        /* bootstrap bug fix when modal doesn't close when you go backwards while modal is open */
        $scope.$on("$destroy", function(){
            angular.element(document.querySelector(".modal-backdrop")).remove();
            angular.element(document.querySelector("body")).removeClass("modal-open");
        });

        $scope.campaignTypeList = [{
            name: 'Акция',
            type: 1
        }];
        $scope.segmentsList = [
            {
                name: 'СТО',
                type: 1
            }, {
                name: 'Магазин',
                type: 2
            }, {
                name: 'Автопарк',
                type: 3
            }, {
                name: 'Владелец автомобиля',
                type: 4
            }
        ];

        $scope.selectedArticles = {selected: []};
        $scope.selectedBrands = {selected: []};
        $scope.selectedSegments = {selected: []};
        $scope.selectedRegions = {selected: []};
        $scope.selectedCampaignType = {
            selected: ''
        };
        $scope.campaigns_discount = 0;
        $scope.ru_regions_checkbox = false;

        angular.element("#sendPushNotification").on('hidden.bs.modal', function(){
            $scope.goBack();
        });

        $scope.dateValidation = function () {
            ($scope.campaigns_period_from >= $scope.campaigns_period_to) ? $scope.dateError = true : $scope.dateError = false;
        };
        $scope.addAllRussianRegions = function () {
            if ($scope.ru_regions_checkbox) {
                $scope.ru_regions_checkbox = false;
            } else {
                $scope.ru_regions_checkbox = true;
                $scope.selectedRegions = {selected: []};
            }
        };

        $scope.modalReset = function () {
            $scope.imageUploaderForm.picFile = {};
        };
        $scope.delPhoto = function () {
            $scope.campInfo.image = null;
            $scope.campInfo.image_new = null;
            $scope.campInfo.image_new_name = null;
            $scope.image_changed = true;
        };
        $scope.uploadImg = function (dataUrl, name) {
            $scope.campInfo.image = dataUrl;
            $scope.campInfo.image_new = dataUrl;
            $scope.campInfo.image_new_name = name;
            angular.element('#changeImageModal').modal('hide');
            $scope.image_changed = true;
        };
        $scope.modalReset = function () {
            $scope.imageUploaderForm.picFile = {};
        };
        var blobImageUpload = function () {
            return new Promise(function (resolve, reject) {
                if ($scope.campInfo.image_new !== null) {
                    CampaignsService.addCampaignPhoto({
                        discount_company_id: $routeParams.campaign_id,
                        campaign_photo: $scope.campInfo.image_new,
                        campaign_photo_name: $scope.campInfo.image_new_name
                    }).then(function () {
                        resolve();
                        $scope.image_changed = true;
                    })
                } else if ($scope.campInfo.image === null && $scope.campInfo.image_received !== null) {
                    CampaignsService.deleteCampaignPhoto({
                        discount_company_id : $routeParams.campaign_id
                    }).then(function () {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        };

        var getRegions = function () {
            CampaignsService.getRegions().then(function (response) {
                console.log(response);
                $scope.regionsList = response.result;

                getSingleCampaign();
            })
        };
        getRegions();
        $scope.getBrands = function (str) {
            if (str.length > 2) {
                SharedService.getBrands({
                    type: 1,
                    brand_name: str
                }).then(function (response) {
                    $scope.brandList = [];
                    for ( var i = 0; i < response.result.length; i++) {
                        $scope.brandList.push({name: response.result[i]});
                    }
                })
            }
        };

        var getSingleCampaign = function () {
            CampaignsService.getCampaigns({
                discount_company_id: $routeParams.campaign_id
            }).then(function (response) {
                console.log(response);
                $scope.campInfo = response.result;
                $scope.campInfo.image_new = null;
                $scope.campInfo.image_new_name = null;
                $scope.campaigns_name = $scope.campInfo.name;
                $scope.campaigns_description = $scope.campInfo.description;
                $scope.campInfo.is_active ? $scope.activate_campaign = true : $scope.activate_campaign = false;
                $scope.selectedCampaignType = {
                    selected: $scope.campInfo
                };
                for (var t = 0; t < $scope.campaignTypeList.length; t++) {
                    if ($scope.campInfo.type === $scope.campaignTypeList[t].type) {
                        $scope.selectedCampaignType.selected = $scope.campaignTypeList[t];
                    }
                }
                $scope.campaigns_period_from = moment($scope.campInfo.date_start).format("YYYY-MM-DD");
                $scope.campaigns_period_to = moment($scope.campInfo.date_end).format("YYYY-MM-DD");
                $scope.campaigns_discount = $scope.campInfo.discount;
                if ($scope.campInfo.regions.length > 0) {
                    for (var r = 0; r < $scope.campInfo.regions.length; r++) {
                        for (var j = 0; j < $scope.regionsList.length; j++) {
                            if ($scope.campInfo.regions[r].region_id === $scope.regionsList[j].id) {
                                $scope.selectedRegions.selected.push($scope.regionsList[j]);
                            }
                        }
                    }
                } else {
                    $scope.ru_regions_checkbox = true;
                }
                for (var a = 0; a < $scope.campInfo.spare_parts.length; a++) {
                    $scope.selectedArticles.selected.push({name:$scope.campInfo.spare_parts[a]});
                }
                for (var b = 0; b < $scope.campInfo.brands.length; b++) {
                    $scope.selectedBrands.selected.push({name:$scope.campInfo.brands[b]});
                }
                for (var s = 0; s < $scope.campInfo.segments.length; s++) {
                    for (var i = 0; i < $scope.segmentsList.length; i++) {
                        if ($scope.campInfo.segments[s] === $scope.segmentsList[i].type) {
                            $scope.selectedSegments.selected.push($scope.segmentsList[i]);
                        }
                    }
                }

            });
        };


        $scope.saveCampaign = function () {
            $scope.loadingIndicator = true;
            var requests = [];
            var deferred = $q.defer();
            requests.push(deferred.promise);

            var parsedDateFrom = moment($scope.campaigns_period_from).format("YYYY-MM-DD");
            var parsedDateTo = moment($scope.campaigns_period_to).format("YYYY-MM-DD");
            var spare_parts_article = [];
            var segments = [];
            var brands = [];
            var regions = [];

            for (var a = 0; a < $scope.selectedArticles.selected.length; a++) {
                spare_parts_article.push({article: $scope.selectedArticles.selected[a].name});
            };

            for (var s = 0; s < $scope.selectedSegments.selected.length; s++) {
                segments.push($scope.selectedSegments.selected[s].type);
            };

            for (var b = 0; b < $scope.selectedBrands.selected.length; b++) {
                brands.push($scope.selectedBrands.selected[b].name);
            };

            for (var r = 0; r < $scope.selectedRegions.selected.length; r++) {
                regions.push({place_id: $scope.selectedRegions.selected[r].place_id});
            };


            console.log({
                discount_company_id: $routeParams.campaign_id,
                name: $scope.campaigns_name,
                description: $scope.campaigns_description,
                type: $scope.selectedCampaignType.selected.type,
                date_start: parsedDateFrom,
                date_end: parsedDateTo,
                discount: $scope.campaigns_discount,
                regions: !$scope.ru_regions_checkbox ? regions : [],
                spare_parts: spare_parts_article,
                brands: brands,
                segments: segments,
                is_active: $scope.activate_campaign ? 1 : 0
            });
            CampaignsService.createCampaigns({
                discount_company_id: $routeParams.campaign_id,
                name: $scope.campaigns_name,
                description: $scope.campaigns_description,
                type: $scope.selectedCampaignType.selected.type,
                date_start: parsedDateFrom,
                date_end: parsedDateTo,
                discount: $scope.campaigns_discount,
                regions: !$scope.ru_regions_checkbox ? regions : [],
                spare_parts: spare_parts_article,
                brands: brands,
                segments: segments,
                is_active: $scope.activate_campaign ? 1 : 0
            }).then(function (response) {
                console.log(response);

                blobImageUpload()
                    .then(function () {
                        deferred.resolve('SYNCHRONOUS');
                    });
            });

            $q.all(requests)
                .then(function () {
                    GlobalNotificationService.trigger('Кампания успешно изменена', 'success');
                    $scope.loadingIndicator = false;
                    if ($scope.activate_campaign) {
                        angular.element('#sendPushNotification').modal('show');
                    } else {
                        $scope.goBack();
                    }
                })
        };

        $scope.sendPushNotification = function (message) {
            $scope.loadingIndicator = true;
            console.log({
                message: message,
                type: 2
            });
            SharedService.sendPushNotification({
                message: message,
                discount_company_id: +$routeParams.campaign_id,
                type: 2
            }).then(function (res) {
                angular.element('#sendPushNotification').modal('hide');
                $timeout(function () {
                    $scope.loadingIndicator = false;
                });
                $location.path('/campaigns');
            })
        };

        $scope.goBack = function () {
            $location.path('/campaigns');
            $scope.$apply();
        };


    }]);