angular.module('appPaginationState', [])
    .directive("paginationState", ['$window', '$timeout','$rootScope', '$localStorage', function ($window, $timeout, $rootScope, $localStorage) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                if (!$localStorage.pagination_state) {
                    $localStorage.pagination_state = {};
                }
                if (scope.onPagePaginations) {
                    if ($localStorage.pagination_state[$rootScope.pagination_id] === undefined) {
                        $localStorage.pagination_state[$rootScope.pagination_id] = {};
                        for (var i = 0; i < scope.onPagePaginations; i++) {
                            var i2 = i + 1;
                            $localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + i2] = {
                                currentPage: 1,
                                pageSize: 100,
                                sortField: '',
                                reverseOrder: null
                            };
                        }
                    } else {
                        for (var y = 0; y < scope.onPagePaginations; y++) {
                            var y2 = y + 1;
                            if (!$localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]) {
                                $localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2] = {
                                    currentPage: 1,
                                    pageSize: 100,
                                    sortField: '',
                                    reverseOrder: null
                                }
                            }
                            if (!$localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]['currentPage']) {
                                $localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]['currentPage'] = 1;
                            }
                            if (!$localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]['pageSize']) {
                                $localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]['pageSize'] = 100;
                            }
                            if (!$localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]['sortField']) {
                                $localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]['sortField'] = '';
                            }
                            if (!$localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]['reverseOrder']) {
                                $localStorage.pagination_state[$rootScope.pagination_id]['pagination_' + y2]['reverseOrder'] = null;
                            }
                        }
                    }
                }
            }
        }
    }]);
